.selectbox-placeholder{
    color: rgba(255, 255, 255, 0.80) !important;
}
.selectbox{
    position: relative;
}
.selectbox-dropdownMenu{
    position: absolute;
    top: 38px;
    border-radius: 0px 0px 12px 12px;
    border-right: 1px solid #FF0078;
    border-bottom: 1px solid #FF0078;
    border-left: 1px solid #FF0078;
    background: #0A090A;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    z-index: 1;
}

.selectbox-dropdownMenu-item{
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    padding: 12px;
    list-style-type: none;
}
.selectbox-dropdownMenu-item:last-child{
    border-radius: 0px 0px 12px 12px;
}

.selectbox-dropdownMenu-item:hover {
    background-color: #181718;
    cursor: pointer;
    color: #FFF;
    font-weight: 700;
}