.ant1-contest.hidden {
    display: none;
}

.ant1-contest {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-height: 32px;
    background: rgba(35, 35, 35, 1);
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 8px 12px ;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}

.ant1-contest sup {
    font-size: 10px;
}

.ant1-contest img {
    position: relative;
    top: 4px;
}

.ant1-contest a {
    color: white;
    text-decoration: none;
}