.device-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.device-link h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 48px;  
    padding: 0 20px;
    max-width: 664px;
}

.device-link .pin {
    margin-bottom: 32px;
    position: relative;
}

.device-link .pin #pin-ver-alert{
    position: absolute;
    top:100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

.pincode-input-container .pincode-input-text{
    width: 48px !important;
    height: 48px !important;
    margin-right: 4px !important;
}

.pincode-input-container .pincode-input-text:nth-child(3){
    margin-right: 13px !important;
}

.pincode-input-container .pincode-input-text:nth-child(6){
    margin-right: 0 !important;
}

.device-link #link-verify-button {
    min-width: 129px;
    height: 40px;
}

.device-link #link-instructions {
    font-size: 14px;
    font-weight: 700;
    margin-top: 44px;
    margin-bottom: 90px;
    padding-inline: 20px;
    line-height: 25px;
}

.device-link #link-instructions ul {
    list-style-type: decimal !important;
}

.device-link #link-instructions ul li {
    font-weight: 400;
}

.device-link #link-instructions div:first-child {
    display: flex;
    align-items: flex-start;
}

.device-link #link-instructions div #info-icon {
    margin-right: 8px;
}

@media only screen and (min-width: 600px) {

    .device-link {
        margin-top: 120px;
    }

    .device-link h3 {
        font-size: 24px; 
        padding: 0; 
    }

    .device-link .pin {
        margin-bottom: 54px;
    }

    .pincode-input-container .pincode-input-text{
        width: 85px !important;
        height: 90px !important;
        margin-right: 16px !important;
    }

    .pincode-input-container .pincode-input-text:nth-child(3){
        margin-right: 40px !important;
    }

    .device-link #link-verify-button {
        min-width: 140px;
        height: 43px;
    }

    .device-link #link-instructions {
        font-size: 16px;
        margin-top: 52px;
    }

    .device-link #link-instructions div:first-child {
        align-items: center;
    }

    .device-link #link-instructions ul li {
        margin-bottom: 8px;
    }

    .device-link #link-instructions ul {
        padding-inline-start: 45px;
    }

}