.usual-questions-container {
 background-color: #232323;
 padding-top: 64px;
 padding-bottom: 80px;
}

.usual-questions {
    background-color: #232323;
    padding-inline: 20px;
    max-width: 1000px;
    color: #FFFFFF;
}

.usual-questions h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 22px;
}   

.usual-questions ul {
    list-style: none;
    /*list-style-image: url("../../../public/images/pink-arrow.png");*/
}

.package-carousel ul li {          
    margin-left: 25px;
}

.usual-questions ul li:before {
    content: url("../../../public/images/pink-arrow.png");
    margin-right: 16px;
    margin-left: -25px;
}

.homepage-accordion-title {
    height: 62px;
    padding: 0 24px !important;
}

.homepage-accordion-title p{
    font-size: 16px;
    font-weight: 500;
}

.homepage-accordion-details{
    padding: 0 24px !important;
    line-height: 130%;
    font-weight: 300;
}

@media only screen and (min-width: 600px) {

    .usual-questions h3 {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        margin: 32px 0 32px 0;
    }

    .homepage-accordion-title {
        height: 78px;
    }

    .homepage-accordion-title p{
        font-size: 24px;
        font-weight: 400;
    }


}