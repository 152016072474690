#header-wrapper {
    width: 100%;
    z-index: 99;
}

#header-wrapper.homepage {
    position: fixed;
}
.avatarHero{
    cursor: pointer;
    width: 89px;
    height: 40px;
}

#page-header.dark {
    background: black;
    position: inherit;
}

#page-header.transparent {
    background: linear-gradient(180deg, #000000 16.88%, rgba(0, 0, 0, 0) 100%);
}

#page-header {
    box-shadow: none;
    padding: 15px 0;
}

#page-header .actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#page-header .actions div{
    display: flex;
}

#page-header .actions #action-buttons {
    display: flex;
    gap: 12px;
}

#page-header .actions #language-dropdown {
    position: relative;
}

#page-header .actions #language-dropdown button#language-button {
    font-size: 16px;
}

#page-header .actions #language-dropdown .languages {
    position: absolute;
    left: 15%;
}

#page-header .actions #language-dropdown .languages.hidden {
    display: none;
}

.language-option {
    color: white;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    padding: 8px;
}
.language-option.hidden {
   display: none;
}

#page-header .actions #user-avatar {
    cursor: pointer;
}

#page-header .ant1-logo {
    cursor: pointer;
    width: 82px;
}

#page-header .toolbar {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0;
}

/* profile popper */
.profile-popper {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
}

.profile-popper .profile-wrapper {
    background-color: #FFFFFF;
}

.profile-popper .profile-email {
    font-weight: 700; 
    padding: 16px 16px 12px;
}

.profile-popper .profile-link {
    padding: 12px 16px 18px;
    cursor: pointer;
}

.profile-popper .logout {
    padding: 18px 16px 12px;
    background-color: #323232;
    color: #FFFFFF;
    cursor: pointer;
}

#sign-in-button,#register-button {
    font-size: 16px;
    min-width: 95px;
    height: 35px;
    border-radius: 8px;
}

@media only screen and (min-width: 600px) {

    #page-header {
        padding: 8px 0;
    }

    #page-header .toolbar {
        padding: 16px 80px 0;
    }

    #page-header .actions {
        height: 64px;
        align-items: center;
    }

    #page-header .actions #action-buttons {
        gap: 16px;
    }

    #page-header .ant1-logo {
        width: 118px;
    }

    #page-header .actions #language-dropdown button#language-button {
        font-size: 18px;
    }
    
    .language-option {
        font-size: 18px;
    }

    /* profile popper */
    .profile-popper {   
        font-size: 16px;
    }

    #sign-in-button,#register-button {
        min-width: 111px;
    height: 43px;
    }
        

     
}