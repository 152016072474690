.ant-image-slider {
    display: flex;
    align-items: center;
    height: 500px;
    min-width: 676px;
}

.ant-image-slider > div {
    flex: 1;
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: auto 100%; */
    background-size: cover;
    transition: all .6s cubic-bezier(0.25, 0.4, 0.45, 1.4);
    width: 115px;
    height: 432px;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
}

.ant-image-slider > div.active {
    flex: 3;
    height: 100%;
    width: 327px;
    opacity: 1;
    border: 2px solid #FFFFFF;
}