#enter-coupon {
    color: white;
    margin-top: 4px;
}

#enter-coupon #enter-coupon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 20px;
    width: 100%;
}

.coupon-wrapper{
    background: rgba(49, 49, 49, 0.50);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(13px);
    width: 100%;
    margin: 85px 0 0 0;
    padding: 36px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
}

.coupon-textbox-wrapper{
    padding: 11px 24px;
    border-radius: 8px;
    background: #020303;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    gap: 16px;
    border: 1px solid #31313100;
    align-items: center;
}
.coupon-textbox-wrapper .coupon-input input {
    color: rgba(255, 255, 255, 0.50);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 210px;
    background-color: transparent;
    border: none;
    outline: none;
}
.coupon-input {
    position: relative;
}
.coupon-input .coupon-input-error{
    position: absolute;
}
.coupon-inner-wrapper{
    position: relative;
    color: #c64e4e;
}
.coupon-error{
    border: 1px solid #c64e4e;
}
.coupon-inner-wrapper span{
    position: absolute;
    bottom: -26px;
    font-size: 14px;
}
.coupon-textbox-wrapper button{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: #FF0078;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
}

.coupon-textbox-wrapper button:disabled{
    opacity: 50%;
}

.coupon-textbox-button{
    width: 12px;
    height: 12px;
    background: url("../../../public/images/buttonArrowSubmit.png") no-repeat center;
}

#enter-coupon #enter-coupon-wrapper #enter-code-button {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

@media only screen and (max-width: 600px){
    .coupon-wrapper{
        flex-direction: column;
    }
}

@media only screen and (min-width: 600px) {
    #enter-coupon #enter-coupon-wrapper {
        padding: 21px 0 0 0;
    }
    #enter-coupon #enter-coupon-wrapper #enter-code-button {
        /*font-weight: 400;*/
        font-size: 20px;
        line-height: 23px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.32);
    }
}