.questionnaire-wrapper{
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 94px;
    align-items: center;
    margin-right: 30px;
    margin-left: 30px;
}
.questionnaire-wrapper h1 {
    margin: 0;
}
.questionnaire-goBack{
    width: max-content;
    padding: 0 !important;
}
.questionnaire-content{
    display: flex;
    flex-direction: column;
    gap: 44px;
    width: 100%;
}

.questionnaire-submit-wrapper,
.questionnaire-content{
    max-width: 1032px;
}

.questionnaire-questions{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.questionnaire-questions div {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex-direction: column;
}
.questionnaire-questions div h2{
    margin: 0;
    border-bottom: solid white 1px;
    padding-bottom: 8px;
    width: 100%;
}
.questionnaire-questions div span {
    font-size: 14px;
    font-weight: 300;
}
.questionnaire-questions ul {
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin: 0;
    list-style-type: none;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
}

.questionnaire-other-wrapper{
    display: flex;
    flex-direction: column;
    gap: 12px
}
.questionnaire-other-wrapper span,
.questionnaire-questions ul li label span{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: white !important;

    .questionnaire-other-hidden-span{
        font-size: 14px !important;
        font-weight: 300 !important;
    }
}
.questionnaire-other-hidden-wrapper{
    gap: 4px !important;
}
.questionnaire-submit-wrapper{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.questionnaire-submit {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 8px;
    border: none;
    background: #FF0078;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    /*height: max-content;*/
}

.questionnaire-submit:disabled{
    cursor: unset;
    opacity: 0.3;
}