.generic-error-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 82px;
    max-width: 700px;
}

.generic-error-wrapper .base-message {
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.generic-error-wrapper .base-message.centered {
    text-align: center;
}

.generic-error-wrapper #gn-icon{
    font-size: 36px;
    margin-right: 16px;
}
.generic-error-wrapper .secondary-wrapper {
    font-weight: 400;
}
.generic-error-wrapper .secondary-message {
    font-size: 16px;
    text-align: center;
}

@media only screen and (min-width: 600px) {

    .generic-error-wrapper {
        margin-top: 120px;
    }

    .generic-error-wrapper .base-message {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .generic-error-wrapper .secondary-wrapper {
        margin-bottom: 54px;
    }

}

.secondary-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 69px;
}