.box {
    margin: auto;
}

.padded {
    padding-top: 72px;
}

.small {
    max-width: 400px;
}

.medium {
    max-width: 600px;
}

.big {
    max-width: 800px;
}

.ant1-logo {
    text-align: center;
}

.MuiButtonBase-root.MuiButton-text{
    padding: 0 20px;
}

.MuiButtonBase-root.MuiButton-text.blue .MuiButton-label{
    color: #3A86FF;
    font-weight: 300;
}

.MuiButtonBase-root.MuiButton-text.white .MuiButton-label{
    color: white;
}

.MuiButtonBase-root.MuiButton-text.grey .MuiButton-label{
    color: #ACACAC;
}

.homepage .default .MuiOutlinedInput-root {
    color: black;
    background-color: white;
    border-radius: 5px;
}

.homepage .default .MuiOutlinedInput-root button {
    border-radius: 0 5px 5px 0 !important;
}

.homepage .default .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border: none;
}

.logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

label {
    margin-bottom: 5px;
}

.m-16 {
    margin-bottom: 16px;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(45deg) !important;
}

.no-padding .MuiAlert-message {
    padding: 0 !important;
}

.validated .MuiOutlinedInput-notchedOutline {
    border-color: blueviolet ;
}

.MuiButton-contained.Mui-disabled {
    background-color: #FF0078 !important;
    color: white !important;
    opacity: 0.7;
}

.MuiButton-outlined {
    color: white !important;
    border: 1px solid white !important;
}

.desktop-hidden {
    display: inherit !important;
}

.mobile-hidden {
    display: none !important;
}

#card-in-use-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
}

.MuiAccordion-root.Mui-expanded {
    margin: 8px 0 !important;
    border-color: #FF0078 !important;
}

@media only screen and (min-width: 600px) {

    .padded {
        padding-top: 80px;
    }

    .desktop-hidden {
        display: none !important;
    }

    .mobile-hidden {
        display: inherit !important;
    }

    #card-in-use-actions {
        flex-direction: row;
        margin-top: 80px;
    
    }

}