.product-card-wrapper-hover{
    /*flex-grow: 1;*/
    /*flex: 1;*/
    min-width: var(--product-min-width);
    max-width: var(--product-max-width);

    height: var(--product-height);

    width: 100%;
    /*width: min-content;*/
}

.product-card-wrapper{
    width: 100%;
    height: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 36px 24px;
    box-sizing: border-box;

    background: #070707;
    box-shadow: 20px 12px 32px 0px rgba(0, 0, 0, 0.30);

    border-radius: 16px;
    border: 1px solid black;

    cursor: pointer;

    top: 0;
    transition-delay: .2s;
    transition-duration: .5s;
    transition-property: top;
}
.product-card-wrapper-hover:hover .product-card-wrapper {
    border-color: rgba(255, 0, 120, 1);
    top: -12px;
}

.product-card-wrapper-hover.vertical {
    width: 100%;
    max-width: unset;
    height: var(--vertical-product-height);
}
.product-card-wrapper-hover.vertical .product-card-wrapper {
    flex-direction: row;
    align-items: center;
    gap: 64px;
    padding: 36px;
}

.product-card-wrapper-hover.vertical:hover .product-card-wrapper {
    position: relative;
    top: -8px;
}

.product-card-wrapper .product-card-ribbon{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: max-content;
    padding: 8px 32px;
    border-radius: 41px;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;

    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.product-card-wrapper .product-card-upper{
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.product-card-wrapper-hover.vertical .product-card-wrapper .product-card-upper {
    flex-direction: row;
    align-items: center;
}

.product-card-wrapper .product-card-upper .product-card-title{
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-wrap: nowrap;

    padding: 8px 24px;
    border-radius: 41px;

    display: block;
    text-align: center;
}

.product-card-wrapper .product-card-upper .product-card-usps ul{
    list-style-image: url("../../../../../public/images/white-arrow.png");
    gap: 16px;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.product-card-wrapper .product-card-upper .product-card-usps ul li {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 18.2px */
    text-wrap: wrap;
}

.product-card-wrapper .product-card-upper .product-card-usps ul li span{
    font-weight: 700;
}

.product-card-wrapper .product-card-lower {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
}
.product-card-wrapper .product-card-lower .product-card-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.product-card-wrapper .product-card-lower .product-card-price .product-card-price-tag {
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.product-card-wrapper .product-card-lower .product-card-price .product-card-price-frequency {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.product-card-wrapper .product-card-lower .product-card-cta {
    width: 100%;
    padding: 8px 56px;
    border-radius: 8px;
    border: 1px solid #FFF;
    background-color: transparent;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    text-wrap: nowrap;

    cursor: pointer;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.product-card-wrapper-hover:hover > .product-card-wrapper .product-card-lower .product-card-cta {
    background-color: rgb(255, 0, 120);
    border-color: rgb(255, 0, 120);
}