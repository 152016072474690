.phone-verification-wrapper {
    text-align: left;
    padding: 20px;
}

.phone-verification-wrapper p {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-wrap: pretty;
}

label {
    font-size: 14px;
    font-weight: 400;
}

.phone-verification-wrapper form {
    margin: 32px 32px 0 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
}

.phone-verification-wrapper form .default.error input {
    color: #C64E4E;
}

.phone-verification-wrapper form .sendPinButton.desktop {
    display: none;
}

.phone-verification-wrapper .form-fields {
    display: flex;
    justify-content: space-around;
}

.phone-verification-wrapper form .form-fields .field:first-child {
    margin-right: 20px;
}

.phone-verification-wrapper .field {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    position: relative;
}

.phone-verification-wrapper form .form-fields .field #country-code-field {
    width: 115px;
}

.phone-verification-wrapper form .form-fields .field #phone-number-field {
    width: 200px;
}

.flag-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}

.phone-verification-wrapper form .information-box{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.07);
    padding: 12px 16px;
    max-width: 404px;
}
.phone-verification-wrapper form .ctas{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (min-width: 600px) {

    .phone-verification-wrapper p {
        font-size: 24px;
    }
    
    .phone-verification-wrapper form .sendPinButton.mobile {
        display: none;
    }

    .phone-verification-wrapper form .sendPinButton.desktop {
        display: inline-block;
    }

    .phone-verification-wrapper form .form-fields .field #wrong-number-alert {
        position: absolute;
        top: 100%;
    }
}

@media only screen and (max-width: 410px) {
    .phone-verification-wrapper .form-fields {
        flex-direction: column;
        gap: 16px;
    }
}