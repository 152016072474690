.pickprogram-wrapper{
    margin-top: 44px;
    margin-right: 24px;
    margin-left: 24px;

    display: flex;
    flex-direction: column;
    gap: 32px;

    container-type: inline-size;
    container-name: pickprogram-content;
}
.pickprogram-wrapper .pickprogram-upper .pickprogram-header-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: max-content;
    margin: 0 auto;
}

.pickprogram-wrapper .pickprogram-header-switch {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    /*gap: 62px;*/
    padding: 6px;
    background: #2B2B2B;
    border-radius: 67px;

    position: sticky;
    top: 20px;
    z-index: 1;
}

.pickprogram-wrapper .pickprogram-header-switch .pickprogram-header-switch-selected{
    position: absolute;
    background: #FF0078;
    height: calc(100% - 12px);
    margin-bottom: 6px;
    border-radius: 30px;
    width: 50%;
    left: calc(50% - 6px);

    transition: right 0.3s ease, left 0.3s ease;
}

.pickprogram-wrapper .pickprogram-header-switch .pickprogram-header-switch-selected.multy{
    left: 6px;
}

.pickprogram-wrapper .pickprogram-header-switch button {
    padding: 10px 32px;
    z-index: 1;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 50%;
}

@media screen and (min-width: 447px) {
    .pickprogram-wrapper .pickprogram-header-switch button {
        text-wrap: nowrap;
    }
}

.pickprogram-wrapper .pickprogram-upper .pickprogram-header-wrapper .pickprogram-header .pickprogram-header-title {
    text-wrap: pretty;
}

.pickprogram-wrapper .pickprogram-upper .pickprogram-header-wrapper .pickprogram-header {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    text-wrap: balance;
    gap: 8px;
}

.pickprogram-wrapper .pickprogram-upper .pickprogram-header-wrapper .pickprogram-header .pickprogram-header-title {
    color: #FFF;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pickprogram-wrapper .pickprogram-upper .pickprogram-header-wrapper .pickprogram-header .pickprogram-header-subtitle {
    color: #FFF;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.42);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}



.cancellation-ctas {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    gap: 16px;
}

.cancellation-ctas button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    min-width: 223px;
    border-radius: 8px;

    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */

    padding: 12px 0px;
}

.cancellation-ctas .cancellation-primary{
    border: 1px solid #FF006E;
    background: #FF006E;
}

.cancellation-ctas .cancellation-secondary{
    border-radius: 8px;
    border: 1px solid #FFF;
}




































.current-tester{
    /*min-width: 285px;*/
    /*width: 100%;*/
    /*max-width: 360px;*/
    height: 521px;
    /*flex-grow: 1;*/
    /*flex-basis: 285px;*/
    background-color: white;
}
/*-----------------------------------*/
.pickprogram-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}
.pickprogram-content > div#Lite {
    order: unset;
}

@container pickprogram-content (min-width: 583px) {
    .pickprogram-content.even:has(:nth-child(4)){
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
    }

    .pickprogram-content.even {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: unset;
        max-width: 732px;
        margin: 0 auto;
    }


    .pickprogram-content.even > div {
        width: unset;
    }

    .pick-program-current-program.even {
        margin: 0 auto;
    }
    .pick-program-current-program.even .pick-product-wrapper.current{
        max-width: 732px;
    }

}

@container  pickprogram-content (min-width: 1176px) {
    .pickprogram-content.even {
        max-width: unset;
        margin: unset;
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }
    .pickprogram-content.even > div#Lite {
        order: unset;
    }
}

@container pickprogram-content (min-width: 879px) {
    .pickprogram-content.odd {
        flex-direction: row;
        justify-content: center;
    }
    .pickprogram-content.odd > div {
        width: unset;
    }
    .pickprogram-content.odd > div#Lite {
        order: unset;
    }
}

.pick-program-current-program {
    display: flex;
    justify-content: center;
}

@container pickprogram-content (min-width: 879px){

    .pick-program-current-program.odd .pick-product-wrapper.current {
        transition-duration: unset;
        display: flex;
        flex-direction: row;
        padding: 24px 32px;
    }

    .pick-program-current-program.odd .pick-product-wrapper.current,
    .pick-program-current-program.odd.disclaimers {
        max-width: 1104px;
    }
    .pick-product-wrapper.current .pick-product-lower .pick-product-usps ul {
        flex-direction: row;
    }
}

@container pickprogram-content (min-width: 584px) {

    .pick-program-current-program.even .pick-product-wrapper.current {
        transition-duration: unset;
        display: flex;
        flex-direction: row;
        padding: 24px 32px;
    }
    .pick-program-current-program.even,
    .pick-program-current-program.even.disclaimers {
        max-width: 732px;
    }
}

@container pickprogram-content (min-width: 1176px){

    .pickprogram-content.even:has(:nth-child(4)) +
    .pick-program-current-program.even .pick-product-wrapper.current,
    .pickprogram-content.even:has(:nth-child(4)) + .pick-program-current-program.even.disclaimers {
        max-width: 1476px;
    }
}







