.cash-coupon-wrapper #goBackButton {
    padding: 0;
}

.cash-coupon-wrapper h1 {
    margin: 32px 0 36px;
    font-size: 20px;
    font-weight: 700;
}

.cash-coupon-wrapper p {
    margin: 0 0 22px;
    font-size: 14px;
    font-weight: 400;
}

.cash-coupon-wrapper .coupon-top-section {
    padding: 20px
}

.cash-coupon-wrapper form {
    display: flex;
    flex-direction: column;
}

.cash-coupon-wrapper form .field{
    max-width: 230px;
    position: relative;
}

.cash-coupon-wrapper form .field .Mui-error{
    color: #C64E4E;
}

.cash-coupon-wrapper form .field #invalid-coupon{
    position: absolute;
    top: 100%;
}

.cash-coupon-wrapper form .field input{
    height: 40px;
}

.cash-coupon-wrapper form #cashButton{
    float: right;
    margin-top: 46px;
    width: 150px;
}

.cash-coupon-wrapper {
    background-image:  url("../../../public/images/coupons/coupon redemption mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

@media only screen and (min-width: 600px) {

    .cash-coupon-wrapper {
        background-image:  url("../../../public/images/coupons/coupon redemption web.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }

    .cash-coupon-wrapper h1 {
        margin: 56px 0 32px;
        font-size: 24px;
    }

    .cash-coupon-wrapper p {
        margin: 0 0 28px;
        font-size: 16px;
    }

    .cash-coupon-wrapper form {
        flex-direction: row;
        align-items: flex-end;
        padding: 0;
    }

    .cash-coupon-wrapper form .field{
        max-width: max-content;
    }

    .cash-coupon-wrapper form #cashButton{
        margin-left: 30px;
        margin-top: 0;
        float: left;
        width: 140px;
    }

    .cash-coupon-wrapper .coupon-top-section {
        padding: 70px 200px 0;
    }

}