.container-wrapper{
    --cardBigW: 317px;
    --cardBigH: 190px;

    --cardSmallW: 273px;
    --cardSmallH: 164px;
}

.container-wrapper.mobile {
    --cardBigW: 273px;
    --cardBigH: 164px;

    --cardSmallW: 218px;
    --cardSmallH: 131px;
}


.container-wrapper{
    width: 100%;
    display: flex;
    position: relative;
}
#container {
    width: 100%;
    position: relative;
}
.container.hasArrows{
    margin-left: 50px;
}

.container-wrapper .container .slideC {
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    width: var(--cardBigW);
    height: var(--cardBigH);
}

.container-wrapper .container .slideC.hasNotActive {
    width: var(--cardSmallW);
}

.container-wrapper .container .slideC.mobile {
    margin: 0 auto;
}

.container-wrapper .container .slideC .slide {
    transition: transform 500ms ease 0s, opacity 500ms ease 0s,
    visibility 500ms ease 0s;
    position: absolute;
    top: 0;
    /*border-radius: 12px;*/
}

.card-carousel-arrow-wrapper{
    height: var(--cardBigH);
}
.card-carousel-arrowBack, .card-carousel-arrowNext{
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
}
.card-carousel-arrowNext{
    right: 0px;
}





/*dots*/
.container-wrapper .dots {
    display: flex;
    gap: 16px;
    margin-top: 26px;
    justify-content: center;
}

.container-wrapper .dots .dot {
    height: 12px;
    width: 12px;
    background: rgba(255, 0, 120, 0.5);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    cursor: pointer;
}

.container-wrapper .dots .dot.active {
    background: rgba(255, 0, 120, 1);
}





































/*CARD ELEMENT*/
.card-carousel {
    width: var(--cardBigW);
    height: var(--cardBigH);
}

.card-carousel.card-carousel-small{
    width: var(--cardSmallW);
    height: var(--cardSmallH);
}

.card-carousel{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 28px 22px;
    box-sizing: border-box;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(235deg, rgba(0, 0, 0, 0.15) 29.85%, rgba(255, 255, 255, 0.00) 112.03%), #313131;
    border: 1px solid #313131;

    border-radius: 12px;
}

.card-carousel.selected {
    background: linear-gradient(235deg, rgba(0, 0, 0, .15) 29.85%, hsla(0, 0%, 100%, 0) 112.03%), #313131;
    border: 1px solid #fff;
}


.card-carousel .card-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-carousel .card-top .cardType-icon {
    width: 45px;
    height: 35px;
}

.card-carousel .card-top .active-text {
    color: #35F153;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    display: flex;
    flex-direction: row;
    gap: 3px;
    text-wrap: nowrap;
    align-items: center;
}


.card-carousel .card-top .settings-wrapper {
    position: relative;
}

.card-carousel .card-top .settings-wrapper .settings-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.card-carousel .card-top .settings-wrapper .settings-dropdown{
    position: absolute;
    display: flex;
    flex-direction: column;
    /*gap: 12px;*/
    padding: 0;
    border-radius: 4px;
    border: 1px solid #FF0078;
    background-color: #0A090A;
    right: 0;
    width: max-content;
}
.card-carousel .card-top .settings-wrapper .settings-dropdown button {
    background: none;
    border: none;
    cursor: pointer;
    outline: inherit;
    width: 100%;

    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 19.5px */
    letter-spacing: 0.5px;
    text-align: left;
}

.card-carousel .card-top .settings-wrapper .settings-dropdown button:first-child {
    padding: 12px 12px 4px 12px;
}
.card-carousel .card-top .settings-wrapper .settings-dropdown button:last-child {
    padding: 4px 12px 12px 12px;
}

.card-carousel .card-top .settings-wrapper .settings-dropdown button:hover {
    background-color: #181718;
    cursor: pointer;
    color: #FFF;
}

.card-carousel .card-bot{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.account-card-delete-wrapper{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
    margin-top: 130px;
}