.textbox-wrapper{
    max-width: 482px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.textbox-top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.textbox-top .textbox-title-wrapper{
    display: flex;
    align-items: center;
    gap:4px
}
.textbox-title{
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}
.textbox-redirectEdit {
    color: #FF0078;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    cursor: pointer;
}

.textbox-bot{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding-bottom: 8px;
    padding-right: 12px;
    border-bottom: 1px solid #FF0078;
    cursor: pointer;
    outline: none;
}
.textbox-bot.disabled{
    cursor: unset;

}
.textbox-bot .isClickable{
    cursor: pointer;
}

.textbox-bot .textbox-input{
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.9px;
}
.textbox-bot.disabled{
    border-bottom: 1px solid #313131;
}

@media only screen and (max-width: 850px) {
    .textbox-wrapper{
        max-width: unset;
    }

    .custom-date-picker{
        max-width: unset !important;

    }
}







.calendar-wrapper{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    /*width: 494px;*/
    height: 366px;
    gap: 25px;
    padding: 25px 25px 32px 25px;
    border-radius: 12px;
    border: 0.5px solid #FF0078;
    background: #0A090A;
    z-index: 1;
}


.calendar-wrapper .calendar-header{
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.calendar-wrapper .calendar-header span{
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #1D1C1D;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    width: 100%;
    text-align: center;
}
.calendar-wrapper .calendar-header span.selected{
    background: #FF0078;
    color: white;
}

.calendar div div {
    color: #FFF;
    text-align: center;
    padding: 8px 16px;

    border-radius: 30px;

    color: rgba(255, 255, 255, 0.75);

    /* Body 1 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}

.calendar div  .selected {
    color: white;
    font-weight: 700;
    background: #FF0078;
}


/* CustomDatePicker.css */

.custom-date-picker {
    font-family: Arial, sans-serif;
    position: relative;
    max-width: 482px;
    width: 100%;
}

.calendar {
    overflow: auto;

    color: rgba(255, 255, 255, 0.75);
    text-align: center;

    /* Body 1 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}

.days-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
}

.day {
    padding: 5px;
    text-align: center;
    cursor: pointer;
}

.day.disabled {
    color: #ccc;
    pointer-events: none;
}

.day:hover:not(.disabled) {
    background-color: #f0f0f0;
}

/* CustomDatePicker.css */

/* Remaining CSS remains the same */

.month-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.prev-month,
.next-month {
    border: none;
    background: none;
    cursor: pointer;
    color: white;
}

.next-month.hidden{
    visibility: hidden;
}

.textbox-date-hidden{
    display: none;
}

/* CustomDatePicker.css */

/* Remaining CSS remains the same */

.calendar div{
    gap: 8px;
}
.years-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
}

.year {
    padding: 5px;
    text-align: center;
    cursor: pointer;
}

.year.selected {
    background-color: #f0f0f0;
}

.calendar div div:not(.selected):hover {
    background: rgba(49, 49, 49, 0.35);
}

.days-container .weekday,
.days-container .empty-day{
    pointer-events: none;
}

/* CustomDatePicker.css */

/* Remaining CSS remains the same */

.months-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.month {
    padding: 5px;
    text-align: center;
    cursor: pointer;
}
.month.disabled{
    color: #ccc;
    pointer-events: none;
}
.month.selected {
    background-color: #f0f0f0;
}

.month:hover {
    background-color: #f0f0f0;
}


@media only screen and (min-width: 850px) {
    .calendar-wrapper{
        width: 100%;
        min-width: 456px;
    }
}

@media only screen and (min-width: 850px) {

}

/*@media only screen and (max-width: 1016px) {*/
/*    .calendar-wrapper{*/
/*        width: 100%;*/
/*    }*/
/*}*/
