.pin-verification-wrapper {
  padding: 80px 38px;
}

.pin-verification-wrapper button.blue {
  padding: 0;
}

.pin-verification-wrapper .pin {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 66px;
  position: relative;
}

.pin-verification-wrapper .pin #pin-ver-alert{
  position: absolute;
  top:100%;
}

.pin-verification-wrapper .pin .pincode-input-container {
  justify-content: space-between;
}

.pin-verification-wrapper .pin input:not(:last-child){
  margin-right: 16px !important;
}

.pin-verification-wrapper p {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 29px;
}

.pin-verification-wrapper .countdown {
  color: #3A86FF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pin-verification-wrapper .re-send {
  display: flex;
  flex-direction: column;
}

.pin-verification-wrapper .re-send button .MuiButton-label {
  font-weight: 700 !important;
}

.pin-verification-wrapper .re-send,.pin-verification-wrapper .countdown {
  font-size: 16px;
  font-weight: 700;
}

.pin-verification-wrapper .re-send > span,.pin-verification-wrapper .countdown > span {
  font-weight: 400;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pin-verification-wrapper .buttons-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

@media only screen and (min-width: 600px) {
  .MuiGrid-root.desktop-center {
    justify-content: center;
  }

  .pin-verification-wrapper p {
    margin-bottom: 18px;
  }

  .pin-verification-wrapper .pin {
    margin-bottom: 42px;
  }

  .pin-verification-wrapper .pin .pincode-input-container {
    justify-content: center;
  }

  .pin-verification-wrapper p {
    font-size: 22px;
  }

  .pin-verification-wrapper button#goBackButton {
    display: none;
  }

}