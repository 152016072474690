.chargify-js-wrapper {
    padding-top: 70px;
    padding: 40px;
    overflow: hidden;
}

#card-number {
    position: relative;
    width: fit-content;
    height: 95px;
}

#card-number iframe{
    width: 292px !important;
}

/*The problem here is that in mobile, chargify *ON INITIAL LOAD*
returns 2 iframes instead of one. to fix this, because the elements were creating gaps,
i display none the first element and display unset the last element.*/

/*nth-child(2) because we have the icon.*/
#card-number iframe:nth-child(2) {
    display: none !important;
}

#card-exp-month iframe:nth-child(1),
#card-exp-year iframe:nth-child(1),
#card-cvv iframe:nth-child(1),
#card-first-name iframe:nth-child(1),
#card-last-name iframe:nth-child(1){
    display: none !important;
}

#card-number iframe:last-child,
#card-exp-month iframe:last-child,
#card-exp-year iframe:last-child,
#card-cvv iframe:last-child,
#card-first-name iframe:last-child,
#card-last-name iframe:last-child
{
    display: unset !important;
    border: unset !important;
}


#card-number #card-lock-icon {
    position: absolute;
    left: 92%;
    top: 16%;
}

#card-exp-month iframe {
    width: 62px !important;
    margin-right: 16px;
}

#card-exp-year iframe {
    width: 64px !important;
}

#card-cvv iframe {
    width: 75px !important;
    overflow: hidden;
}

.card-date {
    display: flex;
    height: 105px;
}

#card-cvv {
    height: 105px;
}

h1#chargify-title, h3#chargify-sub-title {
    text-align: left;
    margin: 0;
}

.card-info-wrapper {
    display: flex;
}

.card-info-wrapper .card-date {
    margin-right: 48px;
}

h1#chargify-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
}

h3#chargify-sub-title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 46px;
}

.cfy-input {
    display: flex;
    flex-direction: column;
}

.cfy-input label{
    font-size: 14px;
    font-weight: 400;
}

#chargify-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    max-width: 386px;
    margin: auto;
}

#chargify-buttons #payment-button {
    width: 110px;
    height: 40px;
}

#card-first-name iframe {
    width: 115px !important;
    margin-right: 24px;
}

#card-last-name iframe {
    width: 154px !important;
}

.card-name-wrapper {
    display: flex;
}

.card-name-wrapper div{
    height: 89px;
}

.chargify-js-content #legal-info {
    max-width: 386px;
    margin: auto;
    font-size: 14px;
    font-weight: 400;
}

@media only screen and (min-width: 600px) {

    #card-number iframe{
        width: 386px !important;
    }

    .chargify-js-wrapper {
        padding-top: 150px;
    }

    .chargify-js-content form{
        width: fit-content;
        margin: auto;
    }

    h1#chargify-title, h3#chargify-sub-title {
        text-align: center;
    }
    
    h1#chargify-title {
        font-size: 36px;
        font-weight: 800;
        margin-bottom: 17px;
    }
    
    h3#chargify-sub-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 71px;
    }

    .card-info-wrapper .card-date {
        margin-right: 60px;
    }

    /* #card-exp-year {
        margin-left: 80px;
    } */

    .card-name-wrapper {
        display: flex;
    }

    #card-first-name iframe {
        width: 135px !important;
    }

    #card-last-name iframe {
        width: 168px !important;
    }

    #chargify-buttons #payment-button {
        width: 119px;
        height: 43px;
    }

}