.page-footer {
    width: 100%;
    padding: 24px 0px 16px;
    background-color: black;
    color: white;
    margin-top: auto;
}

.MuiTypography-colorPrimary {
    color: white;
}

.social-icons img {
    width: 33px;
    height: auto;
}
.social-icons a {
    display: flex;
}

.l1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
}

.l1 .l2 {
    display: flex;
    flex-direction: column;
}

.l1 .l2 a {
    cursor: pointer;
    color: white;
    font-weight: 100;
    font-size: 14px;
    text-align: left;
    padding: 10px 0;
}
.social-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
}
.social-icons{
    display: flex;
    flex-direction: row;
    gap: 14px;
}

@media only screen and (min-width: 1110px) {

    .page-footer {
        padding: 70px 0px 10px;
    } 

    .link-pair {
        flex-direction: row;
    }

    .l1 {
        justify-content: center;
    }

    .l1 .l2 {
        flex-direction: row;
    }

    .l1 .l2 a {
        padding: 0 20px;
    }
}