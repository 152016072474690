.homepage {
    --product-min-width: 330px;
    --product-max-width: 420px;
    --product-height: 453px;
    --vertical-product-height: 149px;
    --products-horizontal-gap: 12px;
    --products-vertical-gap: 16px; /*only on vertical products*/

    --max-horizontal-items: 3;

    --max-container-width: calc(
            var(--product-max-width) * var(--max-horizontal-items) +
            var(--products-horizontal-gap) * (var(--max-horizontal-items) - 1)
    );
    --min-container-width: calc(
            var(--product-min-width) * var(--max-horizontal-items) +
            var(--products-horizontal-gap) * (var(--max-horizontal-items) - 1)
    ); /*search for usages in media queries*/
}

.homepage {
    overflow: hidden;
}

.remind-sign-up {
    padding: 24px 20px 0;
    margin-top: 30px;
}

.remind-sign-up div.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage #homepage-logo {
    width: 77px;
}

@media only screen and (min-width: 600px) {

    .remind-sign-up {
        padding: 30px 0 0;
        margin-top: 66px;
    }

    .homepage #homepage-logo {
        width: 143px;
    }

}

.landing-informationmodal > p > a{
    color: white;
}