.account-wrapper{
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    margin-top: 85px;
}

.account-header{
    max-width: 1096px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 39px;
}

.account-header .account-title{
    color: #FFF;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.account-header .account-progress-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    background: rgba(49, 49, 49, 0.50);
    padding: 25px 32px 40px 32px;
    gap: 12px;
    box-sizing: border-box;
}

.account-header .account-progress-section .account-progress-section-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.account-header .account-progress-section .account-progress-section-top span{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
}

.account-header .account-progress-section .account-progress-section-middle {
    color: rgba(255, 255, 255, 0.65);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 24px */
}

.account-header .account-progress-section .account-progress-section-bot {
    height: 16px;
    width: 100%; /* Adjust to your custom width */
    background: radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
            radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
            radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
            radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
            radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
            radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
            radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
            radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
            radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
            repeating-linear-gradient(#561534, #561534) 4px 50% /
      calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}

.account-header .account-progress-section .account-progress-section-bot.complete1 {
    background: radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
    repeating-linear-gradient(#FF0078, #FF0078) 4px 50% /
      calc(12.5% + 8px) 25%,
    repeating-linear-gradient(#561534, #561534) 4px 50% /
    calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}
.account-header .account-progress-section .account-progress-section-bot.complete2 {
    background: radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
    repeating-linear-gradient(#FF0078, #FF0078) 4px 50% /
      calc(25% + 0px) 25%,
    repeating-linear-gradient(#561534, #561534) 4px 50% /
    calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}

.account-header .account-progress-section .account-progress-section-bot.complete3 {
    background: radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
    repeating-linear-gradient(#FF0078, #FF0078) 4px 50% /
      calc(37.5% + 0px) 25%,
    repeating-linear-gradient(#561534, #561534) 4px 50% /
    calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}

.account-header .account-progress-section .account-progress-section-bot.complete4 {
    background: radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
    repeating-linear-gradient(#FF0078, #FF0078) 4px 50% /
      calc(50% + 0px) 25%,
    repeating-linear-gradient(#561534, #561534) 4px 50% /
    calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}

.account-header .account-progress-section .account-progress-section-bot.complete5 {
    background: radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
    repeating-linear-gradient(#FF0078, #FF0078) 4px 50% /
      calc(62.5% + 0px) 25%,
    repeating-linear-gradient(#561534, #561534) 4px 50% /
    calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}

.account-header .account-progress-section .account-progress-section-bot.complete6 {
    background: radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
    repeating-linear-gradient(#FF0078, #FF0078) 4px 50% /
      calc(75% - 8px) 25%,
    repeating-linear-gradient(#561534, #561534) 4px 50% /
    calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}

.account-header .account-progress-section .account-progress-section-bot.complete7 {
    background: radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
    radial-gradient(circle, #561534 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
    repeating-linear-gradient(#FF0078, #FF0078) 4px 50% /
      calc(87.5% - 8px) 25%,
    repeating-linear-gradient(#561534, #561534) 4px 50% /
    calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}

.account-header .account-progress-section .account-progress-section-bot.complete8 {
    background: radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 0% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 12.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 25% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 37.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 50% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 62.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 75% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 87.5% 0 /
      16px 16px,
    radial-gradient(circle, #FF0078 50%, rgba(255, 255, 255, 0) 50.2%) 100% 0 /
      16px 16px,
    repeating-linear-gradient(#FF0078, #FF0078) 4px 50% /
      calc(100% - 8px) 25%,
    repeating-linear-gradient(#561534, #561534) 4px 50% /
    calc(100% - 8px) 25%;
    background-repeat: no-repeat;
}



.account-content{
    max-width: 1096px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 72px;
}
.account-content .account-data{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 68px;
}

.account-content .account-data .account-connect-data{
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
}

.account-content .account-data .account-connect-data .account-connect-data-title {
    width: 100%;
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 7px;
    border-bottom: 1px solid #FFF;
}

.account-content .account-data .account-connect-data .account-connect-data-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.account-textboxes-grouped {
    display: flex;
    justify-content: space-between;
    gap: 44px;
}

.account-textboxes-grouped .account-textbox-placeholder {
    max-width: 482px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    color: rgba(255, 255, 255, 0.75);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}

.account-textboxes-single{
    display: flex;
}


.account-content .account-data .account-personal-data{
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
}

.account-content .account-data .account-personal-data .account-personal-data-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #FFF;
    padding-bottom: 7px;
}

.account-content .account-data .account-personal-data .account-personal-data-header .account-personal-data-header-isEditing {
    display: flex;
    flex-direction: row;
    gap: 24px;

}

.account-mobile .account-personal-data-header-isEditing .account-personal-data-header-edit,
.account-content .account-data .account-personal-data .account-personal-data-header .account-personal-data-header-edit {
    cursor: pointer;
    color: #FF0078;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.account-mobile .account-personal-data-header-isEditing .account-personal-data-header-cancel,
.account-content .account-data .account-personal-data .account-personal-data-header .account-personal-data-header-isEditing .account-personal-data-header-cancel {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.50);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}
.account-content .account-data .account-personal-data .account-personal-data-title {
    width: 100%;
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.account-content .account-data .account-personal-data .account-personal-data-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subscriptionInformation-wrapper{
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px #000;
    padding: 36px 32px 40px 32px;
    /*flex-wrap: wrap;*/
    box-sizing: border-box;
    gap: 32px;
}

.subscriptionInformation{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /*border-radius: 12px;*/
    /*box-shadow: 0px 4px 4px 0px #000;*/
    /*padding: 36px 32px 40px 32px;*/
    /*flex-wrap: wrap;*/
    /*box-sizing: border-box;*/
    gap: 64px;
}

.subscriptionInformation-wrapper.account-free{
    background: linear-gradient(107deg, rgba(255, 255, 255, 0.05) 42.2%, rgba(0, 0, 0, 0.00) 88.35%), rgba(49, 49, 49, 0.50) !important;
}

.subscriptionInformation-wrapper.account-plus{
    background: linear-gradient(107deg, rgba(151, 53, 241, 0.05) 42.2%, rgba(0, 0, 0, 0.00) 88.35%), rgba(49, 49, 49, 0.50) !important;
}

.subscriptionInformation-wrapper.account-village{
    background: linear-gradient(107deg, rgba(255, 85, 0, 0.05) 42.2%, rgba(0, 0, 0, 0.00) 88.35%), rgba(49, 49, 49, 0.50) !important;
}

.subscriptionInformation-wrapper.account-valuepack{
    background: linear-gradient(107deg, rgba(0, 135, 255, 0.05) 42.2%, rgba(0, 0, 0, 0.00) 88.35%), rgba(49, 49, 49, 0.50) !important;
}

.subscriptionInformation .subscriptionInformation-package{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 42px;
}
.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-title{
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    padding-top: 4px;
}
.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top{
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top .subscriptionInformation-package-package {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    border-radius: 96px;
    padding: 12px 36px;
    align-items: center;
    text-wrap: nowrap;
}

.subscriptionInformation-package-package.account-free{
    color: black !important;
    background: #FFF !important;
}

.subscriptionInformation-package-package.account-plus{
    background: #9735F1 !important;
}

.subscriptionInformation-package-package.account-village{
    background: #F50 !important;
}

.subscriptionInformation-package-package.account-valuepack{
    background: #0087FF !important;
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top .subscriptionInformation-package-upgrade {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 8px;
    background: #FF0078;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    /*height: max-content;*/
    text-wrap: nowrap;
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot-duration{
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot{
    display: flex;
    flex-direction: row;
    gap: 32px;
    /*flex-wrap: wrap;*/
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot .subscriptionInformation-bot-texts{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-wrap: nowrap;
    /*flex-wrap: wrap;*/
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot .subscriptionInformation-bot-texts span{
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot .subscriptionInformation-bot-texts span.field-data{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.8px;
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-cancel{
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
    cursor: pointer;
}

.subscriptionInformation .subscriptionInformation-voucher{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 375px;
    border-left: 2px solid;
    padding: 4px 0px 28px 28px;
    height: max-content;
}
.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-wrapper{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-wrapper-child .subscriptionInformation-voucher-item-wrapper .subscriptionInformation-voucher-name {
    height: unset;
}

.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-wrapper-child {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-item-wrapper.gapped {
    gap: 24px;
}
.subscriptionInformation-voucher.account-free{
    border-color: #FFF !important;
}

.subscriptionInformation-voucher.account-plus{
    border-color: #9735F1 !important;
}

.subscriptionInformation-voucher.account-village{
    border-color: #F50 !important;
}

.subscriptionInformation-voucher.account-valuepack{
    border-color: #0087FF !important;
}


.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header{
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
}

.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header .subscriptionInformation-voucher-title{
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    text-wrap: nowrap;
}

.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header .subscriptionInformation-voucher-add{
    color: #FF0078;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.32px;
    cursor: pointer;
}

.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header .subscriptionInformation-voucher-add.desktop {
    display: flex;
}
.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header .subscriptionInformation-voucher-add.mobile {
    display: none;
}

.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-name{
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 36px */
    letter-spacing: 2.4px;
    height: 60px;
    display: flex;
    align-items: center;
}

.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-texts{
    color: rgba(255, 255, 255, 0.65);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-texts span.field-data{
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.8px;
}

.subscriptionInformation-hasUnsubscribed{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.07);
    padding: 12px 16px;
}

.subscriptionInformation-hasUnsubscribed.past_due{
    max-width: 560px;
}

.subscriptionInformation-hasUnsubscribed span{
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}
.subscriptionInformation-hasUnsubscribed span.clickable {
    color: #FF0078;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    cursor: pointer;
}

.subscriptionInformation-village{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.subscriptionInformation-village .subscriptionInformation-village-header{
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}
.subscriptionInformation-village .subscriptionInformation-village-header span{
    color: #FFF;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
}

.subscriptionInformation-village .subscriptionInformation-village-header img{
    width: 62px;
    height: 24px;
}
.subscriptionInformation-village .subscriptionInformation-village-content{
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
}

.subscriptionInformation-village .subscriptionInformation-village-content .subscriptionInformation-village-voucher {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 24px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.08);
    cursor: pointer;
}

.subscriptionInformation-village .subscriptionInformation-village-content .subscriptionInformation-village-voucher span {
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 27px */
    letter-spacing: 0.36px;
}
.subscriptionInformation-village .subscriptionInformation-village-content .subscriptionInformation-village-field .subscriptionInformation-village-field-title {
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.subscriptionInformation-village .subscriptionInformation-village-content .subscriptionInformation-village-field .subscriptionInformation-village-field-data {
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.subscriptionInformation-village .subscriptionInformation-village-description {
    color: rgba(255, 255, 255, 0.70);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}

.account-content .card-section{
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
}

.account-content .card-section .card-section-header {
    width: 100%;
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 7px;
    border-bottom: 1px solid #FFF;
}

.account-content .card-section .card-section-data{
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.account-content .card-section .card-section-data .card-section-data-data{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.account-content .card-section .card-section-data .card-section-data-data .card-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.account-content .card-section .card-section-data .card-section-data-data .card-title span{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
}

.account-content .card-section .card-section-data .card-section-data-data .card-title button.card-title-button{
    color: #FF0078;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    cursor: pointer;

    background: none;
    border: none;
    padding: 0;
    outline: inherit;
}

.account-content .card-section .card-section-data .card-section-data-data .card-title button.card-title-button:disabled{
    opacity: 0.5;
    cursor: unset;
}

.account-content .card-section .card-section-data .card-section-data-data .card-title button.card-title-button::after {
    content: " κάρτας";
}
.card-section-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
}
.card-section-card-wrapper .card-section-card-select-btn{
    color: #FF0078;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}

.account-content .card-section .card-section-data .card-section-history{
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 100%;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-header{
    color: #FFF;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data{
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 544px;
    overflow: auto;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 12px;
    border-left: 4px solid;
    margin-right: 40px;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper {
    padding: 5.5px 0px;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper:hover{
    background: rgba(49, 49, 49, 0.35);
}

.card-section-history-item.account-free{
    border-color: #FFF !important;
}

.card-section-history-item.account-plus{
    border-color: #9735F1 !important;
}

.card-section-history-item.account-village{
    border-color: #F50 !important;
}

.card-section-history-item.account-valuepack{
    border-color: #0087FF !important;
}


.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data,
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data .card-section-history-item-two-group{
    display: flex;
    flex-direction: row;
    gap: 64px;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper  .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data .card-section-history-item-two-group .card-section-history-item-field,
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper  .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data  .card-section-history-item-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper  .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data .card-section-history-item-two-group .card-section-history-item-field:first-child,
.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper  .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data  .card-section-history-item-field:first-child {
    min-width: 166px;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data .card-section-history-item-two-group .card-section-history-item-field .card-section-history-item-field-top,
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data  .card-section-history-item-field .card-section-history-item-field-top {
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
    text-wrap: nowrap;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data .card-section-history-item-two-group .card-section-history-item-field .card-section-history-item-field-bot,
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data  .card-section-history-item-field .card-section-history-item-field-bot {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-receipt {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-receipt span{
    color: #FF0078;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.8px;
}

.account-content .card-section .card-section-data .card-section-history .card-section-history-expandMore{
    color: #FF0078;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    cursor: pointer;
    width: max-content;
}

.account-content .disconnect-section{
    width: 100%;
    margin-bottom: 32px;
}
.account-content .disconnect-section span{
    cursor: pointer;
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    text-decoration-line: underline;
}

.account-mobile{
    display: none;
}

.account-code-tooltip-elements{
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background: #313131;
    padding: 16px;
}

.account-code-tooltip-elements span,
.account-code-tooltip-elements ul li{
    color: #DDDFE1;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}
.account-code-tooltip-elements ul{
    margin: 0;
    padding: 0 0 0 16px;
}















/*COUPONS*/
.account-coupon-wrapper{
    width: max-content;
    display: flex;
    justify-content: flex-start;
}

.account-coupon-textbox-wrapper{
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    padding: 16px 16px;
    display: flex;
    gap: 16px;
    align-items: center;
}
.account-coupon-textbox-wrapper .account-coupon-input input {
    color: rgba(255, 255, 255, 0.50);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    min-width: 210px;
    background-color: transparent;
    border: none;
    outline: none;
}
.account-coupon-input {
    position: relative;
}
.account-coupon-input .account-coupon-input-error{
    position: absolute;
}
.account-coupon-inner-wrapper{
    position: relative;
    color: #c64e4e;
}
.account-coupon-error{
    border: 1px solid #c64e4e;
}
.account-coupon-inner-wrapper span{
    position: absolute;
    bottom: -26px;
    font-size: 14px;
}
.account-coupon-textbox-wrapper button{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: #FF0078;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
}

.account-coupon-textbox-wrapper button:disabled{
    opacity: 50%;
}

.account-coupon-textbox-button{
    width: 12px;
    height: 12px;
    background: url("../../../public/images/buttonArrowSubmit.png") no-repeat center;
}

#account-enter-coupon #account-enter-coupon-wrapper #account-enter-code-button {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}




@media only screen and (max-width: 1155px) {
    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot {
        gap: 12px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1065px) {
    .subscriptionInformation{
        flex-direction: column;
        gap: 38px;
    }
    .subscriptionInformation .subscriptionInformation-voucher{
        border-left: none;
        border-top: 2px solid;
        padding: 24px 0 0 0;
        width: 100%;
    }
    .subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header {
        width: 100%;
    }
    .subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header .subscriptionInformation-voucher-add {
        padding-right: 8px;
    }
    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot {
        gap: 56px;
        flex-direction: row;
    }
}

@media only screen and (max-width: 850px) {
    .account-textboxes-grouped {
        flex-direction: column;
        gap: 24px;
    }
}

@media only screen and (max-width: 865px) {
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data {
        flex-direction: column;
        gap: 20px;
    }

    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data,
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data .card-section-history-item-two-group{
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data{
        flex-direction: column;
    }
}

.subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot .subscriptionInformation-bot-texts .field-title::after{
    content: ": ";
}

@media only screen and (max-width: 752px) {
    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot .subscriptionInformation-bot-texts{
        flex-direction: column;
        align-items: flex-start;

    }

    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot .subscriptionInformation-bot-texts .field-title::after{
        content: "";
    }
}

@media only screen and (max-width: 668px) {
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data {
        flex-direction: column;
        gap: 20px;
    }

    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data,
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data .card-section-history-item-two-group{
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item .card-section-history-item-data{
        flex-direction: column;
    }
}

@media only screen and (min-width: 600px) {
    .account-coupon-wrapper{
        flex-direction: column;
    }
    #account-enter-coupon #account-enter-coupon-wrapper {
        padding: 21px 0 0 0;
    }
    #account-enter-coupon #account-enter-coupon-wrapper #account-enter-code-button {
        /*font-weight: 400;*/
        font-size: 20px;
        line-height: 23px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.32);
    }
}

@media only screen and (max-width: 576px) {
    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-bot-wrapper .subscriptionInformation-bot {
        margin-top: 8px;
        gap: 16px;
        flex-direction: column;
    }

    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top {
        width: 100%;
    }
}

@media only screen and (max-width: 600px){
    .account-content .card-section .card-section-data .card-section-history .card-section-history-wrapper .card-section-history-data .card-section-history-item-wrapper .card-section-history-item {
        flex-direction: column;
        gap: 24px;
    }
}

@media only screen and (max-width: 500px) {
    .account-desktop {
        display: none !important;
    }
    .account-mobile{
        display: unset !important;
    }
    .account-mobile .account-personal-data-header-isEditing{
        display: flex;
        flex-direction: row;
        gap: 32px;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
    }
    .subscriptionInformation-village .subscriptionInformation-village-content {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 695px) {

    .subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header .subscriptionInformation-voucher-add.desktop {
        display: none;
    }
    .subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header .subscriptionInformation-voucher-add.mobile {
        display: unset;
    }
    .subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-header {
        /*flex-direction: column;*/
        align-items: flex-start;
        /*gap: 16px;*/
    }

    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top .subscriptionInformation-package-upgrade {
        width: 100%;
        box-sizing: border-box;
    }
    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top .subscriptionInformation-package-package {
        width: 100%;
        box-sizing: border-box;
        padding: 16px 36px;
    }

    .account-content .card-section .card-section-data .card-section-data-data {
        width: 100%;
        align-items: center;
    }

    .account-content .card-section .card-section-data .card-title{
        width: 100%;
    }

    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top {
        flex-direction: column;
    }

    #account-enter-coupon .account-coupon-wrapper,
    #account-enter-coupon .account-coupon-wrapper .account-coupon-inner-wrapper{
        width: 100%;
    }
    #account-enter-coupon .account-coupon-wrapper .account-coupon-inner-wrapper .account-coupon-textbox-wrapper{
        justify-content: space-between;
    }
    #account-enter-coupon .account-coupon-wrapper .account-coupon-inner-wrapper .account-coupon-textbox-wrapper .account-coupon-input {
        flex: 1;
    }
    #account-enter-coupon .account-coupon-wrapper .account-coupon-inner-wrapper .account-coupon-textbox-wrapper .account-coupon-input input{
        min-width: unset;
        width: 100%;
    }
}

@media only screen and (max-width: 458px){
    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top .subscriptionInformation-package-package {
        font-size: clamp(16px, 5vw, 24px);
        padding: 16px 20px;
    }
}

@media only screen and (max-width: 430px) {
    .account-card-delete-wrapper {
        flex-direction: column;
    }
}

@media only screen and (max-width: 410px) {
    .account-content .card-section .card-section-data .card-section-data-data .card-title button.card-title-button::after {
        content: "";
    }
}

@media only screen and (max-width: 370px) {
    .subscriptionInformation .subscriptionInformation-package .subscriptionInformation-package-content .subscriptionInformation-package-top .subscriptionInformation-package-package {
        padding: 16px 20px;
    }
}

@media only screen and (max-width: 368px) {
    .subscriptionInformation .subscriptionInformation-voucher .subscriptionInformation-voucher-texts {
        flex-direction: column;
    }
}














/*.account-wrapper {*/
/*    !* max-width: 80%; *!*/
/*    width: fit-content;*/
/*    margin: auto;*/
/*    font-size: 16px;*/
/*    padding-top: 32px;*/
/*    padding-inline: 20px;*/
/*}*/

/*.account-wrapper .account-grid-container.user-info {*/
/*    margin-bottom: 60px;*/
/*}*/

/*.account-wrapper .account-grid-container.package {*/
/*    margin-bottom: 32px;*/
/*}*/

/*.account-wrapper .account-grid-container.village-voucher {*/
/*    margin-bottom: 32px;*/
/*}*/

/*.account-wrapper .account-grid-container.payment {*/
/*    margin-bottom: 48px;*/
/*}*/

/*.account-wrapper .account-grid-container.coupon {*/
/*    margin-bottom: 48px;*/
/*}*/

/*.account-wrapper span.title {*/
/*    font-weight: 500;*/
/*    white-space: nowrap;*/
/*}*/

/*.account-grid-wrapper{*/
/*    !*max-width: min-content;*!*/
/*}*/

/*.account-wrapper h3 {*/
/*    font-size: 20px;*/
/*    font-weight: 700;*/
/*    text-align: center;*/
/*    margin: 0;*/
/*    padding-bottom: 18px;*/
/*}*/

/*.account-wrapper .info-wrapper {*/
/*    white-space: nowrap;*/
/*    font-weight: 400;*/
/*    font-size: 14px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/
/*.account-wrapper .info-wrapper .info-wrapper-email{*/
/*    display: flex;*/
/*    align-items: flex-start;*/
/*}*/
/*.account-wrapper .info-wrapper .info-wrapper-email .info-wrapper-email-inside{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-right: 16px;*/
/*}*/

/*.gridStyle{*/
/*    max-width: max-content;*/
/*    padding: 8px !important;*/
/*}*/
/*.info-email-data-wrapper{*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    gap: 4px;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*}*/

/*.account-wrapper .info-wrapper .info-wrapper-email img{*/
/*    margin-right: 6px;*/
/*}*/

/*.account-wrapper .info-wrapper .info-wrapper-email .img-email-needs-action:hover{*/
/*    cursor: pointer;*/
/*}*/

/*.account-wrapper .info-wrapper.long {*/
/*    white-space: unset;*/
/*}*/

/*.account-wrapper .info-wrapper.long span#email-change {*/
/*    display: flex;*/
/*    !*margin-left: 16px;*!*/
/*}*/

/*.account-wrapper .info-wrapper.long span#email-change.blue {*/
/*    color: #3A86FF;*/
/*    font-size: 0.875rem;*/
/*    align-items: center;*/
/*}*/

/*#email-change.blue:hover{*/
/*    cursor: pointer;*/
/*}*/

/*.account-wrapper .info-wrapper .info {*/
/*    font-weight: 300;*/
/*    font-size: 14px;*/
/*    margin-right: 30px;*/
/*}*/

/*.account-wrapper .date {*/
/*    font-size: 14px;*/
/*    font-weight: 300;     */
/*}*/

/*.account-wrapper #coupon-in-use {*/
/*    font-size: 14px;*/
/*    font-weight: 300;*/
/*    text-align: center;*/
/*    margin: 32px auto 0; */
/*}*/

/*.account-wrapper #active-program {*/
/*    border: 1px solid white;*/
/*    border-radius: 5px;*/
/*    padding: 5px 15px;*/
/*    width: fit-content;*/
/*    font-size: 16px;*/
/*    font-weight: 400;*/
/*}*/

/*.account-wrapper .payment-info .card-details {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.account-wrapper .no-payment-info .no-card-message {*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*    margin-bottom: 16px;*/
/*    margin-top: 6px;*/
/*}*/

/*#upgradeButton-wrapper {*/
/*    margin: 0 auto;*/
/*}*/

/*button#upgradeButton {*/
/*    margin-top: 8px;*/
/*    width: 149px;*/
/*    height: 40px;*/
/*    font-size: 14px;*/
/*    font-weight: 700;*/
/*    border-radius: 10px;*/
/*}*/

/*#email-change.blue#useCouponButton {*/
/*    padding: 0;*/
/*    font-size: 18px;*/
/*}*/

/*#email-change.blue#useCouponButton .MuiButton-label {*/
/*    font-weight: 700;*/
/*}*/

/*#email-change.blue.svod#useCouponButton {*/
/*    margin-top: 32px;*/
/*}*/

/*.account-wrapper #hidden-unsubscribe-button {*/
/*    display: none;*/
/*}*/

/*  !* Create a custom checkbox *!*/

/*  .checkbox-container {*/
/*    display: block;*/
/*    position: relative;*/
/*    padding-left: 26px;*/
/*    margin-bottom: 12px;*/
/*    cursor: pointer;*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*    -webkit-user-select: none;*/
/*    -moz-user-select: none;*/
/*    -ms-user-select: none;*/
/*    user-select: none;*/
/*  }*/

/*  .checkbox-container input {*/
/*    position: absolute;*/
/*    opacity: 0;*/
/*    cursor: pointer;*/
/*    height: 0;*/
/*    width: 0;*/
/*  }*/

/*  .checkbox-container .checkmark {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    height: 16px;*/
/*    width: 16px;*/
/*    background-color: #000000;*/
/*    border: 1px solid #0087FF;*/
/*    border-radius: 3px;*/
/*  }*/

/*  !* On mouse-over, add a grey background color *!*/
/*  .checkbox-container:hover input ~ .checkmark {*/
/*    background-color: #000000;*/
/*  }*/
/*  */
/*  !* When the checkbox is checked, add a blue background *!*/
/*  .checkbox-container input:checked ~ .checkmark {*/
/*    background-color: #000000;*/
/*  }*/

/*  .checkbox-container .checkmark:before {*/
/*    top: -4px;*/
/*    left: 9px;*/
/*    width: 8px;*/
/*    height: 10px;*/
/*    display: block;*/
/*  }*/
/*  */
/*  !* Create the checkmark/indicator (hidden when not checked) *!*/
/*  .checkbox-container .checkmark:after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    display: none;*/
/*  }*/
/*  */
/*  !* Show the checkmark when checked *!*/
/*  .checkbox-container input:checked ~ .checkmark:after {*/
/*    display: block;*/
/*  }*/

/*  .checkbox-container .checkmark:after {*/
/*    bottom: -1px;*/
/*    right: -1px;*/
/*    display: none;*/
/*  }*/
/*  */
/*  !* Style the checkmark/indicator *!*/
/*  .checkbox-container .checkmark:after {*/
/*    left: 7px;*/
/*    top: -5px;*/
/*    width: 6px;*/
/*    height: 16px;*/
/*    border: solid #0087FF;*/
/*    border-radius: 3px;*/
/*    border-width: 0 3px 3px 0;*/
/*    -webkit-transform: rotate(45deg);*/
/*    -ms-transform: rotate(45deg);*/
/*    transform: rotate(45deg);*/
/*  }*/

/*   .checkbox-container .checkmark:before {*/
/*    background-color: black;*/
/*    content: "";*/
/*    position: absolute;*/
/*  }*/

/*.account-wrapper #email-change.blue {*/
/*    font-size: 14px;*/
/*    font-weight: 300;*/
/*}*/

/*.account-wrapper #delete-card-button-mobile-wrapper {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/*.account-wrapper #delete-card-button-mobile {*/
/*    color:  rgba(212, 4, 81, 1);*/
/*    font-size: 14px;*/
/*    font-weight: 300;*/
/*    margin-top: 12px;*/
/*}*/

/*.account-wrapper #delete-card-button-desktop {*/
/*    display: none;*/
/*}*/

/*#delete-card-modal {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    margin: 0 auto;*/
/*}*/

/*#delete-card-modal h3 {*/
/*    margin: 0 0 16px;*/
/*    font-size: 18px;*/
/*    font-weight: 700;*/
/*}*/

/*#delete-card-modal p {*/
/*    !* margin: 0 0 58px; *!*/
/*    font-size: 16px;*/
/*    font-weight: 400;*/
/*    margin: 0 0 16px;*/
/*    !* max-width: 320px; *!*/
/*}*/

/*#delete-card-modal #delete-buttons {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-top: 24px;*/
/*}*/

/*#delete-card-modal #delete-buttons #cancel-delete-card {*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*    margin-bottom: 16px;*/
/*}*/

/*#delete-card-modal #delete-buttons #delete-card-button {*/
/*    min-width: 271px;*/
/*    height: 40px;*/
/*    font-size: 14px;*/
/*    font-weight: 700;*/
/*}*/

/*.account-grid-container.village-voucher .title img {*/
/*    vertical-align: bottom;*/
/*    width: 70px;*/
/*    margin-right: 8px;*/
/*}*/

/*.account-grid-container.village-voucher #village-info {*/
/*    max-width: 593px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 24px;*/
/*}*/

/*.account-grid-container.village-voucher #village-info p,*/
/*.account-grid-container.village-voucher #village-info a {*/
/*    margin: 0;*/
/*    font-weight: 300;*/
/*    font-size: 14px;*/
/*    line-height: 150%;*/
/*}*/
/*.account-grid-container.village-voucher #village-info a,*/
/*.account-grid-container.village-voucher #village-info a:hover,*/
/*.account-grid-container.village-voucher #village-info a:visited,*/
/*.account-grid-container.village-voucher #village-info a:active {*/
/*    color: #3A86FF;*/
/*    text-decoration: none;*/
/*}*/

/*.account-grid-container.village-voucher #village-info span {*/
/*    font-weight: 300;*/
/*    font-size: 14px;*/
/*    line-height: 19px;*/
/*}*/

/*.account-grid-container.village-voucher input {*/
/*    height: 40px;*/
/*    padding: 8px 16px;*/
/*    font-weight: 200;*/
/*    font-size: 20px;*/
/*    line-height: 150%;*/
/*    letter-spacing: 0.02em;*/
/*    background-color: #000000;*/
/*    color: white;*/
/*    border: 1px solid #FFFFFF;*/
/*    border-radius: 8px;*/
/*    text-align: center;*/
/*}*/

/*.account-grid-container.village-voucher #village-copy {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 16px;*/
/*}*/

/*#voucher-value.used {*/
/*    color: rgba(255, 255, 255, 0.3);*/
/*}*/

/*#upgrade-text {*/
/*    text-align: center;*/
/*}*/

/*@media only screen and (min-width: 600px) {*/

/*    .account-wrapper {*/
/*        padding-top: 56px;*/
/*    }*/

/*    .account-wrapper h3 {*/
/*        font-size: 24px;*/
/*        margin-bottom: 50px;*/
/*        margin-top: 0;*/
/*    }*/

/*    .account-wrapper .info-wrapper {*/
/*        font-size: 18px;*/
/*    }*/

/*    .account-wrapper .info-wrapper.long {*/
/*        white-space: nowrap;*/
/*    }*/

/*    .account-wrapper .info-wrapper.long span#email-change {*/
/*        display: unset;*/
/*    }*/

/*    .account-wrapper .info-wrapper .info {*/
/*        font-size: 16px;*/
/*        margin-right: 8px;*/
/*    }*/

/*    .account-wrapper span.title {*/
/*        padding-right: 24px;*/
/*    }*/

/*    .account-wrapper .MuiGrid-root#settings {*/
/*        margin-top: 16px;*/
/*    }*/

/*    button#upgradeButton {*/
/*        margin-top: 0px;*/
/*        width: 137px;*/
/*        height: 43px;*/
/*        font-size: 16px;*/
/*        border-radius: 8px;*/
/*    }*/

/*    #email-change.blue#useCouponButton {*/
/*        font-size: 20px;*/
/*    }*/

/*    #email-change.blue.svod#useCouponButton {*/
/*        margin-top: 0;*/
/*    }*/

/*    .account-wrapper .payment-info .card-details {*/
/*        margin-bottom: 16px;*/
/*    }*/

/*    .account-wrapper .payment-info .card-details button {*/
/*        margin-left: 12px;*/
/*    }*/

/*    .account-wrapper .no-payment-info .no-card-message {*/
/*        font-size: 16px;*/
/*        margin-top: 0;*/
/*    }*/

/*    .account-wrapper .date {*/
/*        font-size: 16px;      */
/*    }*/

/*    .account-wrapper #coupon-in-use {*/
/*        font-size: 16px;*/
/*        margin: 8px 40px;*/
/*    }*/

/*    .account-wrapper #active-program {*/
/*        font-size: 24px;*/
/*    }*/

/*    #upgrade-text {*/
/*        margin: 0;*/
/*        padding: 0;*/
/*        max-width: 280px;*/
/*        text-align: left;*/
/*    }*/

/*    .account-wrapper #account-accordeon-wrapper {*/
/*        width: 588px;*/
/*    }*/

/*    .account-grid-container.account-grid-container.package,*/
/*    .account-grid-container.account-grid-container.payment,*/
/*    .account-grid-container.account-grid-container {*/
/*        margin-bottom: 16px;*/
/*    }*/

/*    .account-grid-container.account-grid-container.user-info {*/
/*        margin-bottom: 32px;*/
/*    }*/

/*    .account-grid-container.account-grid-container.coupon {*/
/*        margin-bottom: 36px;*/
/*    }*/

/*    .account-wrapper #email-change.blue {*/
/*        font-size: 16px;*/
/*    }*/

/*    button#account-cta {*/
/*        margin-top: 52px;*/
/*        margin-left: 160px;*/
/*        font-size: 14px;*/
/*        font-weight: 300;*/
/*    }*/

/*    button#account-cta #cta-icon{*/
/*        font-size: 18px;*/
/*        font-weight: 300;*/
/*        margin-right: 10px;*/
/*    }*/

/*    #delete-card-modal #text-wrapper {*/
/*        padding: 0 57px 0 167px;*/
/*    }*/

/*    .account-wrapper #delete-card-button-mobile-wrapper {*/
/*        display: none;*/
/*    }*/
/*    */
/*    .account-wrapper #delete-card-button-desktop {*/
/*        display: block;*/
/*        padding: 0;*/
/*    }*/

/*    #delete-card-modal h3 {*/
/*        margin: 60px 0 40px;*/
/*        font-size: 24px;*/
/*    }*/

/*    #delete-card-modal p {*/
/*        margin: 0 0 16px;*/
/*           align-self: flex-start;*/
/*    }*/

/*    #delete-card-modal #delete-buttons {*/
/*        flex-direction: row;*/
/*        margin-top: 40px;*/
/*    }*/

/*    #delete-card-modal #delete-buttons #cancel-delete-card {*/
/*        font-size: 16px;*/
/*        margin-bottom: 0;*/
/*    } */

/*    .account-grid-container.village-voucher #village-copy {*/
/*        display: flex;*/
/*        flex-direction: row;*/
/*        gap: 16px;*/
/*    }*/

/*    .account-grid-container.village-voucher #village-info span {*/
/*        font-size: 16px;*/
/*    }*/

/*}*/

/*@media only screen and (min-width: 960px) {*/
/*    .account-wrapper span.title {*/
/*        float: right;*/
/*    }*/
/*}*/

/*.account-email-popup-secondary-wrapper{*/
/*    font-size: 18px;*/
/*    font-weight: 400;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    text-align: center;*/
/*    text-wrap: balance;*/
/*}*/
/*.account-email-popup-secondary-sub{*/
/*    font-size: 16px;*/
/*    padding-top: 12px;*/
/*}*/
