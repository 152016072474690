.pick-product-wrapper{
    min-width: 285px;
    max-width: 360px;
    width: 100%;

    flex-basis: 285px;

    flex-grow: 1;
    min-height: 521px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 36px 24px;
    gap: 24px;

    color: #FFF;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-size: 16px;
    font-weight: 300;

    border-radius: 16px;

    border-radius: 16px;
    border: 1px solid black;

    transition-duration: .3s;

    cursor: pointer;
    position: relative;
}
.pick-product-wrapper.current {
    min-height: unset;
}
.pickprogram-content.single .pick-product-wrapper{
    min-height: min-content;
}
.pick-product-wrapper.isSelected,
.pick-product-wrapper:hover {
    border-color: rgba(255, 0, 120, 1);
}

.pick-product-wrapper.isSelected .pick-product-cta,
.pick-product-wrapper:hover > .pick-product-cta {
    background-color: rgb(255, 0, 120);
    border-color: rgb(255, 0, 120);
}

.pick-product-wrapper .pick-product-ribbon{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: max-content;
    padding: 8px 32px;
    border-radius: 41px;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;

    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.pick-product-wrapper .pick-product-upper {
    display: flex;
    flex-direction: column;
    gap: 27px;
}

.pick-product-wrapper .pick-product-upper .pick-product-title {
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border-radius: 76px;
    text-align: center;
    padding: 8px 24px;
    text-wrap: nowrap;
}

.pick-product-wrapper.monthly .pick-product-upper .pick-product-prices {
    height: 38px;
}

.pick-product-wrapper.monthly .pick-product-upper .pick-product-prices:has(> .pick-product-old-price),
.pick-product-wrapper.monthly .pick-product-upper .pick-product-prices:has(> .pick-product-subline-text) {
    height: unset;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices .pick-product-price-free{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    letter-spacing: -0.4px;
}

.pick-product-wrapper.multiMonthly .pick-product-upper .pick-product-prices {
    height: 89px;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices:has(> span.pick-product-price-free) {
    justify-content: center;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 0 auto;
    position: relative;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices .pick-product-old-price{
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: flex-end;
    height: 24px;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices .pick-product-old-price .pick-product-old-price-price {
    font-size: 20px;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices .pick-product-old-price .crossed-line {
    position: absolute;
    height: 1.5px;
    background-color: #FF0078;
    width: 64px;
    rotate: 160deg;
    top: 11px;
    left: 27px;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices .pick-product-price {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: flex-end;
    height: 38px;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices .pick-product-price .pick-product-price-before {
    font-weight: 400;
}

.pick-product-wrapper .pick-product-upper .pick-product-prices .pick-product-price .pick-product-price-price {
    font-size: 32px;
    font-weight: 700;
}

.pick-product-wrapper .pick-product-cta {
    color: white;
    font-weight: 700;
    line-height: 150%; /* 24px */

    font-size: 16px;
    padding: 8px 56px;
    border-radius: 8px;
    border: 1px solid #FFF;
    background-color: transparent;
    cursor: pointer;
    transition-duration: .3s;
}

.pick-product-wrapper .pick-product-lower{
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.pick-product-wrapper .pick-product-lower .pick-product-usps ul{
    list-style-image: url("../../../../public/images/white-arrow.png");
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.pick-product-wrapper .pick-product-lower .pick-product-usps ul li span{
    font-weight: 700;
}

.pick-product-wrapper .pick-product-lower .pick-product-plus-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    gap: 8px;
}


