.product-tabs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
}

.product-tabs-wrapper h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    margin: 40px 0;
    text-align: center;
}

.product-tabs-wrapper .MuiTab-root:not(:last-child) {
    margin-right: 24px;
}

.product-tabs-wrapper .tab-panel {
    min-height: 500px;
}

.product-tabs-wrapper .tab-content {
    padding: 52px 0 64px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

.product-tabs-wrapper .tab-content.originals {
    display: block;
}

.product-tabs-wrapper .tab-content .description {
    max-width: 437px;
    line-height: 150%;
}

.product-tabs-wrapper .tab-content .description h2 {
    font-weight: 800;
    font-size: 16px;
    margin: 0;
    padding: 24px 0;
    line-height: 1.5;
}

.product-tabs-wrapper .tab-content .description p {
    font-weight: 300;
    font-size: 14px;
    margin: 0;
}

.product-tabs-wrapper .tab-content .description #village-logo {
    width: 189px;
}

.product-tabs-wrapper .tab-content .react-3d-carousel {
    width: 100%;
}

/*** react 3d carousel ***/
.react-3d-carousel {
    height: 350px !important;
}

.react-3d-carousel .slider-container .slider-right {
    margin-right: 0 !important;
}

.react-3d-carousel .slider-container .slider-right div {
    background-image: url("../../../../public/images/3dCarousel/3d-carousel-right.png");
    margin-left: 4px;
}

.react-3d-carousel .slider-container .slider-left div {
    background-image: url("../../../../public/images/3dCarousel/3r-carousel-left.png");
}

.react-3d-carousel .slider-container .slider-left {
    margin-right: 0 !important;
}

.react-3d-carousel .slider-container .slider-right div,
.react-3d-carousel .slider-container .slider-left div {
    border: none !important;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 32px;
}

.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content {
    transform: translateX(-40%) scale(0.8) !important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
    transform: translateX(40%) scale(0.8) !important;
}

.tabs-carousel-mobile {
    display: block;
}

.tabs-carousel-desktop {
    display: none;
}

#tabs-container {
    width: 100%;
}

.product-tabs-wrapper .tab-content .description.originals {
    margin: 24px auto 0;
}

.logo {
    max-height: 90px;
}

@media only screen and (min-width: 400px) {

    /*** react 3d carousel ***/
    .react-3d-carousel {
        height: 450px !important;
    }

    /* .product-tabs-wrapper .tab-content .description.originals {
        margin: 80px auto 0;
    } */

}

@media only screen and (min-width: 950px) {

    .product-tabs-wrapper {
        margin-top: 140px;
    }

    .product-tabs-wrapper h1 {
        font-size: 32px;
        margin: 40px 0;
    }

    /* .product-tabs-wrapper .tab-content .description.originals {
        margin-top: 0;
    } */

    .product-tabs-wrapper .tab-content .description.originals {
        margin: 0;
    }

    .product-tabs-wrapper .tab-content .description h2 {
        font-weight: 800;
        font-size: 24px;
        padding: 44px 0 32px;
    }

    .product-tabs-wrapper .tab-content .description p {
        font-size: 16px;
    }

    .tabs-carousel-mobile {
        display: none;
    }

    .tabs-carousel-desktop {
        display: block;
    }

    .product-tabs-wrapper .tab-content.originals {
        display: flex;
        flex-direction: row-reverse;
    }

    .product-tabs-wrapper .tab-content {
        padding: 72px 0 80px;
        flex-direction: row;
        gap: 32px;
    }
}