.unsubscribe-wrapper {
    text-align: left;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 35px;
}

.unsubscribe-wrapper h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.unsubscribe-wrapper .buttons {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
}

.unsubscribe-wrapper .buttons button {
    margin-inline: 9px;
}

.unsubscribe-wrapper .buttons button {
    width: 188px;
    margin: 10px 0;
}

.unsubscribe-wrapper br.mobile {
    display: inherit;
}

.unsubscribe-wrapper br.desktop {
    display: none;
}

@media only screen and (min-width: 600px) {

    .unsubscribe-wrapper {
        margin-top: 55px;
    }

    .unsubscribe-wrapper h3 {
        font-size: 24px;
        font-weight: 500;
    }

    .unsubscribe-wrapper br.mobile {
        display: none;
    }

    .unsubscribe-wrapper br.desktop {
        display: inherit;
    }

    .unsubscribe-wrapper .buttons {
        flex-direction: row;
    }

    .unsubscribe-wrapper .buttons button {
        width: unset;
        margin: 0 10px;
    }

}