.congrats-page {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.congrats-page .main-message {
    font-weight: 700;
    margin-bottom: 27px;
    margin-top: 48px;
}

.congrats-page .main-message p{
    font-size: 18px;
    margin: 0;
}

.congrats-page .ant1-tv-app {
    display: none;
}

#congrats-banner {
    background-image:  url("../../../public/images/congrats-page/congrats-page-mobile_V4.png");
    background-size: cover;
    height: 565px;
}

#congrats-banner.contest{
    background-image:  url("../../../public/images/congrats-page/congratsContestMobile.png");
}

#congrats-banner .button-wrapper {
    display: none;
    justify-content: center;
    padding-top: 32px;
}

@media only screen and (min-width: 600px) {

    .congrats-page {
        max-width: 60%;
    }

    .congrats-page .main-message {
        font-weight: 800;
        margin-bottom: 80px;
        margin-top: 72px;
    }
    
    .congrats-page .main-message p{
        font-size: 32px;
        margin: 0;
        text-align: center;
    }

    .congrats-page .ant1-tv-app {
        display: flex;
        align-items: center;
        height: 65px;
        margin-top: 52px;
    }

    .congrats-page .ant1-tv-app img{
        width: 92px;
        height: 65px;
    }

    .congrats-page .ant1-tv-app .message {
        display: flex;
        justify-content: space-between;
        height: 100%;
        margin-left: 16px;
    }

    .congrats-page .ant1-tv-app .message .main {
        font-size: 20px;
        font-weight: 400;
    }

    .congrats-page .ant1-tv-app .message a {
        font-size: 20px;
        font-weight: 400;
        text-decoration: underline;
    }

    .congrats-page .ant1-tv-app .message a:visited {
        color: white;
    }

    #congrats-banner {
        background-image:  url("../../../public/images/congrats-page/congrats-page-web_v5.png");
        height: 540px;
    }

    #congrats-banner.contest{
        background-image:  url("../../../public/images/congrats-page/congratsContest.png");
    }

    #congrats-banner .button-wrapper {
        display: flex;
    }

    #congrats-banner #congrats-button {
        width: 213px;
        height: 43px;
        border-radius: 8px;
    }

}

@media only screen and (min-width: 1650px) {

    .congrats-page {
        max-width: 50%;
    }
    
}

.contestTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
}

.contestTitle span {
    font-weight: 400;

}

.congrats-page .main-message:has(.contestTitle) {
    margin-bottom: 36px;
}

.congrats-apps{
    display: flex;
    flex-direction: column;
    /*width: max-content;*/
}