.products-wrapper {
    background-color: #232323;

    padding: 64px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

@media only screen and (min-width: 451px) {
    .products-wrapper {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.products-wrapper .products-title{
    color: #FFF;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    text-align: center;
    text-wrap: pretty;
}

.product-cards-wrapper-outer{
    width: 100%;
    display: flex;
    justify-content: center;
    container-type: inline-size;
    container-name: product-cards-wrapper-outer;

    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.product-cards-wrapper {
    display: flex;
/*    flex-direction: row-reverse;*/
/*    flex-wrap: wrap-reverse;*/
/*    justify-content: center;*/
    gap: var(--products-horizontal-gap);
/*    max-width: var(--max-container-width);*/
/*    width: 100%;*/
}

/*.product-cards-wrapper.noVertical {*/
/*    flex-direction: column;*/
/*    max-width: var(--product-max-width);*/
/*}*/

/*.product-cards-wrapper.noVertical > div {*/
/*    flex-basis: unset;*/
/*}*/

/*@container product-cards-wrapper-outer (min-width: 1014px){ !*min-width: var(--min-container-width)*!*/
/*    .product-cards-wrapper {*/
/*        gap: var(--products-vertical-gap) var(--products-horizontal-gap);*/
/*        flex-direction: row;*/
/*        flex-wrap: wrap;*/
/*    }*/

/*    .product-cards-wrapper.noVertical {*/
/*        flex-direction: row;*/
/*        max-width: var(--max-container-width);*/
/*    }*/
/*    .product-cards-wrapper.noVertical > div {*/
/*        flex-basis: var(--product-min-width);*/
/*    }*/
/*}*/

.products-wrapper .product-cards-wrapper-outer .product-disclaimers{
    max-width: var(--max-container-width);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.products-wrapper .product-cards-wrapper-outer .product-disclaimers p {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
    margin: 0;
}

@container product-cards-wrapper-outer (max-width: 1014px) { /*max-width: var(--min-container-width)*/
    .products-wrapper .product-cards-wrapper-outer .product-disclaimers {
        max-width: calc(var(--product-max-width) * 2 + var(--products-horizontal-gap));
    }
}





.product-cards-wrapper {
    width: -webkit-fill-available;
    max-width: var(--product-max-width);
    flex-direction: column-reverse;
    flex-wrap: wrap-reverse;

    justify-content: center;
}

@container product-cards-wrapper-outer (min-width: 672px) {
    .product-cards-wrapper {
        max-width: calc(var(--product-max-width) * 2 + var(--products-horizontal-gap));
        flex-direction: row;
        flex-wrap: wrap;
    }
    .product-cards-wrapper > div {
        flex: 1;
        width: unset;
    }

    .product-cards-wrapper:has(:nth-child(3)){
        max-width: var(--product-max-width);
        flex-direction: column-reverse;
        flex-wrap: wrap-reverse;
    }

    .product-cards-wrapper:has(:nth-child(3)) > div{
        flex: unset;
        width: -webkit-fill-available;
    }

    .product-cards-wrapper:has(:nth-child(4)) {
        max-width: calc(var(--product-max-width) * 2 + var(--products-horizontal-gap));
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
    }
}

@container product-cards-wrapper-outer (min-width: 1014px) {
    .product-cards-wrapper {
        max-width: calc(var(--product-max-width) * 3 + var(--products-horizontal-gap) * 2) !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }
    .product-cards-wrapper > div {
        flex: 1 !important;
        width: unset !important;
    }
}