#devices {
    padding: 64px 24px;
}

#devices #devices-wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding-inline: 16px;
}

#devices #devices-wrapper .text-part {
    text-align: left;
}

#devices #devices-wrapper .text-part h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    margin: 0;
    margin-bottom: 16px;
}

#devices #devices-wrapper .text-part p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    text-align: left;
}

#devices #devices-wrapper img {
    height: 133px;
}

@media only screen and (min-width: 600px) {
    #devices {
        padding: 120px 0 140px;
    }

    #devices #devices-wrapper{
        flex-direction: row;
        gap: 32px;
    }

    #devices #devices-wrapper .text-part {
        max-width: 441px;
    }

    #devices #devices-wrapper .text-part h2 {
        font-size: 32px;
    }

    #devices #devices-wrapper img {
        height: 238px;
    }
}