/* Bootstrap Classes */
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.d-flex{
    display: flex !important;
}

.justify-content-end{
    justify-content: flex-end !important;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-12{
    flex: 0 0 100%;
    max-width: 100%;
}

.text-start {
    text-align: left !important;
}

.text-end {
    text-align: right !important;
}


.mb-0 {
    margin-bottom: 0 !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.position-relative {
    position: relative !important;
}

.text-center {
    text-align: center !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}


























.payment_info_inner table{
    width: 100%;
}

.main-page{
    box-shadow: unset !important;
}
.main-page h5{
    font-size: 1.25rem !important;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
.main-page {
    width: 210mm;
    min-height: 297mm;
    margin: 10mm auto;
    background: white;
    color: black;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}
.main-page * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-size: 10pt;
    line-height: 1.5;
}

.main-page tbody, td, tfoot, th, thead, tr {
    border-width: 1px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.main-page p {
    margin-top: 0;
}

.main-page tbody{
    border: none !important;
    border-width: 0px !important;
}

.main-page table {
    caption-side: bottom;
    border-collapse: collapse;
}
.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
}
.sub-page {
    padding: 1cm;
    height: 270mm;
}
@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;
    }
    .main-page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}
/* Colors */
.white {
    color: #fff;
}
/* backround colors */
.blue_bg {
    background-color: #22344f;
}
/* Paddings */
.padding_top {
    padding-top: 100px;
}
.xl_padding_top {
    padding-top: 200px;
}
.paid {
    padding-top: 15px;
    padding-bottom: 15px;
}
/* Logo Css */
.img_logo {
    max-width: 180px;
}
.bold {
    font-weight: bold ;
}
.right_info_table {
    min-height: 100px;
}
.paid {
    text-align: center;
    border: 1px solid #000;
}
/* Width */
.w-33 {
    width: 33%;
}
.w-50 {
    width: 50% !important;
}
.w-100 {
    width: 100% !important;
}
.img_logo img {
    width: 100%;
}
.btn-info {
    color: #fff;
    background-color: #d40451;
    border-color: #d40451;
}
.btn-info:hover {
    color: #fff;
    background-color: #6452a0;
    border-color: #6452a0;
}
/* Tabler Css */
tbody, td, tfoot, th, thead, tr {
    border-width: 1px;
    padding-top: 6px;
    padding-bottom: 6px;
}
.ammount_due_inner.w-50 {
    margin-left: auto;
}
tr.no-border td {
    border: 0;
    padding-top: 20px;
}
.back {
    color: #d40451;
    font-size: 16px;
}
.back:hover {
    color: #fff;
    font-size: 16px;
}
.mid {
    font-size: 18px;
}
.grey-bg {
    background-color: #22344f;
    color: #fff;
    border: solid 1px #000;
}
td {
    border: 1px solid #c6c6c6;
}
tbody {
    border: none !important;
    border-width: 0px !important;
}
.no-border {
    border-width: 0px;
}
/* List of invoices Css */
.results {
    background-color: #fff;
    border-radius: 1rem;
}
.results .row {
    border-bottom: 1px solid #000;
    display: flex;
    align-items: center;
}
.pink {
    background-color: #d40451;
}
.row.pink {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.invoice-header-wrapper {
    display: flex;
    width: 210mm;
    margin: 0 auto;
    justify-content: space-between;
}

.invoice-download-btn{
    border: none;
    outline: inherit;

    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 8px;
    background: #FF0078;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}






































@media print {
    #header-wrapper{
       display: none;
    }
    .invoice-header-wrapper{
        display: none;
    }
    .page-footer{
        display: none;
    }
    button{
        display: none;
    }
    #main-page{
        background-color: white;
        width: 210mm;
        height: 297mm;
    }
    .app-wrapper {
        -webkit-print-color-adjust:exact !important;
        print-color-adjust:exact !important;
        background-color: white;
    }

    .blue_bg {
        background-color: #22344f;
    }
    .grey-bg{
        background-color: #22344f;
    }

}