#banner {
    /* background-image: url("../../../public/images/banner/mobile-banner_fifawwc.png"); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    height: 100vh;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#banner #banner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#banner #banner-wrapper #main-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 400px 24px 24px;
}
#banner #banner-wrapper #main-section.bannerContest{
    gap: 20px;
}

#banner #banner-wrapper #main-section .info {
    text-align: center;
    align-self: center;
}
#banner #banner-wrapper #main-section .info.bannerContest{
    max-width: 600px;
}

#banner #banner-wrapper #main-section .info h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    margin: 0;
    margin-bottom: 8px;
}


#banner #banner-wrapper #main-section .info h1.bannerContest {
    text-wrap: pretty;
}

#banner #banner-wrapper #main-section .info p {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    margin: 0;
}

#banner #banner-wrapper #main-section .info p.bannerContest {
     text-wrap: balance;
}
#banner #banner-wrapper #main-section .info.bannerContest p {
    line-height: 130% !important;
}


#banner #banner-wrapper #main-section .price-and-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: auto;
}

#banner #banner-wrapper #main-section .price-and-actions p {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 0;
}

#banner #banner-wrapper #main-section .price-and-actions p span {
    font-weight: 300;
}

#banner #banner-wrapper #main-section .price-and-actions .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

#banner #banner-wrapper #main-section .price-and-actions .buttons button {
    min-width: 278px;
    height: 51px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    border-radius: 8px;
}

#banner #banner-wrapper #main-section .price-and-actions .buttons button #arrow-icon {
    width: 24px;
    height: 24px;
    color: #FF0078;
}

#banner #banner-wrapper #main-section .price-and-actions .terms-and-conditions {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: white;
    /*margin-top: 16px;*/
}

#banner #banner-wrapper #main-section .price-and-actions .terms-and-conditions.contestError > *  {
    color: #C64E4E

}
#banner #banner-wrapper #main-section .price-and-actions .terms-and-conditions a,
#banner #banner-wrapper #main-section .price-and-actions .terms-and-conditions a:active,
#banner #banner-wrapper #main-section .price-and-actions .terms-and-conditions a:visited,
#banner #banner-wrapper #main-section .price-and-actions .terms-and-conditions button {
    color: white;
    font-weight: 400;
    font-size: 14px;
    color: white;
    text-decoration: underline;
}

#banner #banner-wrapper #main-section .price-and-actions .terms-and-conditions button {
    padding: 0;
}

#banner #banner-wrapper #main-section #coupon-wrapper {
    align-self: center;
}

@media only screen and (min-width: 600px) {

    #banner {
        min-height: 100vh;
        /* background-image: url("../../../public/images/banner/header-banner-desktop_Fifa_WWC.png"); */
        background-size: 100%;
        background-position: center;
    }

    #banner #banner-wrapper #main-section {
        padding: 230px 120px 0px;
        padding-left: 80px;
        /* width: 52%; */
    }

    #banner #banner-wrapper #main-section .info {
        align-self: flex-start;
        text-align: left;
    }

    #banner #banner-wrapper #main-section .info h1 {
        font-size: 28px;
    }

    #banner #banner-wrapper #main-section .info p {
        font-size: 24px;
    }

    #banner #banner-wrapper #main-section .price-and-actions {
        align-items: flex-start;
        margin: unset;
    }

    #banner #banner-wrapper #main-section .price-and-actions p {
        font-size: 32px;
    }

    #banner #banner-wrapper #main-section .price-and-actions .buttons button {
        min-width: 325px;
        height: 55px;
        font-size: 20px;
        line-height: 23px;
    }

    #banner #banner-wrapper #main-section #coupon-wrapper {
        align-self: flex-end;
    }

}

@media only screen and (min-width: 900px) {

    #banner #banner-wrapper #main-section {
        padding-left: 120px;
    }
}