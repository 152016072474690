.pop-up .MuiDialog-paper     {
    background-color: black;
    color: white;
    border-radius: 8px;
    min-height: 550px;
}

.pop-up.outlined .MuiDialog-paper{
    border: 0.5px solid #F5F5F5;
}

.pop-up.condensed .MuiDialog-paper{
    min-height: fit-content
}

.pop-up .MuiDialog-paper button.close {
    background-color: black;
    color: #FF0078;
    float: right;
    border: none;
    cursor: pointer;
}

.pop-up.error .MuiDialog-paper{
    margin: 0;
}

.pop-up.error .MuiDialog-paperFullWidth {
    /*width: 100%;*/
}

.pop-up .MuiBackdrop-root {
    background-color: #232323;
    opacity: 0.8 !important;
}