/*.checkbox-wrapper{*/
/*}*/
/*.checkbox-wrapper label{*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    color: rgba(255, 255, 255, 0.75);*/
/*    font-family: Roboto;*/
/*    font-size: 14px;*/
/*    font-style: normal;*/
/*    font-weight: 300;*/
/*    line-height: 150%; !* 21px *!*/
/*    display: flex;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    gap: 8px;*/

/*}*/
.checkbox-wrapper{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
    cursor: pointer;
    text-wrap: wrap;
    margin: 0px;
    max-width: max-content;
    width: 100%;
}
.checkbox-wrapper > input[type="checkbox"]{
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox-wrapper > .checkbox {
    display: inline-block;
    height: 20px;
    min-width: 20px;
    background-repeat: no-repeat;

    background-image: url("../../../../../public/images/account/checkboxUnchecked.svg");
}

.checkbox-wrapper > .checkbox--active {
    background-image: url("../../../../../public/images/account/checkboxChecked.svg");
}

.checkbox-wrapper .checkbox-label{
    color: rgba(255, 255, 255, 0.75);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}




