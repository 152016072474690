.carousel-dot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 73px;
}

.carousel-dot .slides-container .slide.fade{
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

.carousel-dot .slides-container .slide.hide {
    display: none;
}

.carousel-dot .slides-container .slide img {
    max-width: 100%;
}

.carousel-dot .dots {
    display: flex;
    gap: 16px;
    margin-top: 26px;
}

.carousel-dot .dots .dot{
    height: 12px;
    width: 12px;
    background: rgba(255, 0, 120, 0.5);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    cursor: pointer;
}
.carousel-dot .dots .dot.active {
    background: rgba(255, 0, 120, 1);
}

@media only screen and (min-width: 600px) {
    .carousel-dot {
        margin-bottom: 0;
    }
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}