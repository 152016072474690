.terms-and-conditions-wrapper iframe {
    border: none;
    width: 100%;
    height: 500px;
}

@media only screen and (min-width: 600px) {

    .terms-and-conditions-wrapper iframe {
        height: 700px;
    }

}