.preview-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    
}

.preview-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    /*padding-top: 64px;*/
    margin-top: 138px;
}

.preview-header-logo{
    width: auto;
    height: 37px;
}

.preview-header-text{
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
    margin: 0px;
    text-wrap: balance;
}

.preview-body{
    display: flex;
    flex-direction: row;
    gap: 22px; 
    width: 100%;
    justify-content: center;
    align-items: center;
}

.preview-background{
    background-color: #232323;
    height: 250px;
    width: 100%;
    position: absolute;
}

.preview-item .preview-body-imgs{
    border-width: 0px 4px 4px 0px;
    border-style: solid;
    border-radius: 16px;
    width: 260px;
    height: auto;
    position: relative;
}

.preview-body-imgs{
    z-index: 9;
    position: relative;
}
.preview-item{
    background-color: black;
}


/* mobile */
.preview-body .preview-item-mobile{
    display: none;
}

@media only screen and (max-width: 1175px) {
    .preview-header{
        flex-direction: column;
    }

    .preview-header .preview-header-text{
        font-size: 24px;
        padding: 0 15px;
    }

    .preview-background,
    .preview-body > .preview-body-item-wrapper,
    .preview-body > .preview-body-item-wrapper > .preview-item{
        display: none; 
    }

    .preview-body .preview-item-mobile{
        display: block;
    }

    .preview-header{
        gap: 24px;
    }

    .preview-wrapper{
        gap: 32px;
    }

}