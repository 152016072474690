.textbox-wrapper{
    max-width: 482px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.textbox-top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.textbox-top .textbox-title-wrapper{
    display: flex;
    align-items: center;
    gap:4px
}

.textbox-top .textbox-title-wrapper .textbox-title-wrapper-tooltip{
    position: relative;
    display: flex;
}

.textbox-title-wrapper-tooltip-wrapper{
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.textbox-title-wrapper-tooltip-wrapper img {
    left: 4px;
    position: absolute;
    top: calc(50% - 15px);
}

.textbox-top .textbox-title-wrapper .textbox-title-wrapper-tooltip .textbox-title-wrapper-tooltip-image{
    cursor: pointer;
}
.textbox-top .textbox-title-wrapper .textbox-title-wrapper-tooltip > div{
    position: absolute;
    z-index: 1;
    bottom: -68.5px;
    left: 15px;
    padding-left: 13px;
    width: max-content;
    max-width: 234px;
}

.textbox-title{
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}
.textbox-informationText{
    color: rgba(255, 255, 255, 0.50);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}
.textbox-redirectEdit {
    color: #FF0078;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    cursor: pointer;
}

.textbox-bot{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #FF0078;
    position: relative;
}

.textbox-bot.textbox-bot-error{
    border-color: #F20D3B;
}

.textbox-bot .isClickable{
    cursor: pointer;
}

.textbox-bot:has(.phoneNumber-wrapper){
    cursor: unset !important;
}

.textbox-bot .phoneNumber-wrapper{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    position: relative;
}
.textbox-bot .phoneNumber-wrapper .phoneNumber-selectBox-wrapper{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    align-items: center;
}
.textbox-bot .phoneNumber-wrapper .phoneNumber-selectBox-wrapper .phoneNumber-selectBox{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.9px;
}
.textbox-bot .phoneNumber-wrapper .phoneNumber-selectBox-wrapper .phoneNumber-selectBox.disabled {
    cursor: unset !important;
}

.textbox-bot .phoneNumber-wrapper .phoneNumber-selectBox-wrapper .phoneNumber-selectBox .phoneNumber-selectBox-text-wrapper{
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    cursor: pointer;
}

.textbox-bot .phoneNumber-wrapper .phoneNumber-selectBox-wrapper .phoneNumber-selectBox .phoneNumber-selectBox-text-wrapper span{
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.9px;
}
.textbox-bot .phoneNumber-wrapper .phoneNumber-selectBox-wrapper .phoneNumber-selectBox .phoneNumber-selectBox-text-wrapper .phoneNumber-selectBox-text{
    width: 25px;
}
.textbox-bot .phoneNumber-wrapper .phoneNumber-selectBox-wrapper .phoneNumber-selectBox .phoneNumber-selectBox-text-wrapper .phoneNumber-selectBox-code{
    width: 45px;
}
.textbox-bot .phoneNumber-wrapper .phoneNumber-selectBox-wrapper .textbox-input{
    flex: 1;
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.9px;
}

.textbox-input::placeholder{
    color: rgba(255, 255, 255, 0.25) !important;
}

.textbox-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px black inset; /* Change the box shadow color to match your background */
    -webkit-text-fill-color: white !important; /* Set text color to inherit to prevent Chrome overriding */
}

 .textbox-input.textbox-input-disabled{
    pointer-events: none;
}

.textbox-error{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    top: 47.8px;
}
.textbox-error span{
    color: #F20D3B;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}

.phoneNumber-dropdownMenu{
    position: absolute;
    top: 40px;
    border-radius: 0px 0px 12px 12px;
    border-right: 1px solid #FF0078;
    border-bottom: 1px solid #FF0078;
    border-left: 1px solid #FF0078;
    background: #0A090A;
    min-width: 60px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.phoneNumber-dropdownMenu-item{
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    padding: 12px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 22px;
    justify-content: center;
}
.phoneNumber-dropdownMenu-item:last-child{
    border-radius: 0px 0px 12px 12px;
}

.phoneNumber-dropdownMenu-item:hover {
    background-color: #181718;
    cursor: pointer;
    color: #FFF;
    font-weight: 700;
}


.textbox-bot.disabled{
    border-bottom: 1px solid #313131;
}

@media only screen and (max-width: 850px) {
    .textbox-wrapper{
        max-width: unset;
    }
}
