.download-apps.grey {
    background-color: #1D2124;
    padding: 20px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.download-apps .title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 24px;
}

.download-apps.account .title {
    font-size: 12px;
    font-weight: 700;
}

.download-apps .logos {
    display: flex;
    justify-content: flex-start;
}

.download-apps .logos .download-mobile-link {
    background-color: #000000;
    width: auto;
    height: 54px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.download-apps.account .logos .download-mobile-link {
    justify-content: center;
}

.download-apps.account .logos .download-mobile-link {
    padding: 0 8px;
    width: 143px;
}

.download-apps .logos .download-mobile-link img{
    width: 146px;
    height: 52px;
    margin-right: 10px;
}

.download-apps.hidden, .download-apps.grey.hidden {
    display: none;
}

@media only screen and (min-width: 600px) {

    .download-apps .title {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 28px;
    }

    .download-apps .logos {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .download-apps .logos .download-mobile-link {
        margin-right: 19px;
        width: auto;
    }

    .download-apps .logos .download-mobile-link img{
        width: 149px;
        margin-right: 10px;
    }

}